import React, { useEffect, useState } from 'react';
import Progress from '../ui/Progress';  // Assuming you have a progress bar component

const DriverPassengerCompatibility = () => {
  const [compatibilityScore, setCompatibilityScore] = useState(null);
  const [driverPreferences, setDriverPreferences] = useState({});
  const token = localStorage.getItem('authToken');  // Retrieve token from localStorage

  useEffect(() => {
    const fetchCompatibilityScore = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/compatibility-score`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Pass token in header
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch compatibility score');
        }

        const data = await response.json();
        setCompatibilityScore(data.score);
        setDriverPreferences(data.driverPreferences);
      } catch (error) {
        console.error('Error fetching compatibility score:', error);
      }
    };

    fetchCompatibilityScore();
  }, [token]);

  return (
    <div className="card">
      <div className="card-header">Driver-Passenger Compatibility</div>
      <div className="card-content">
        {compatibilityScore !== null && (
          <div>
            <p>Compatibility Score:</p>
            <Progress value={compatibilityScore} max={100} />
            <p>{compatibilityScore}% Match</p>
          </div>
        )}
        {Object.keys(driverPreferences).length > 0 && (
          <ul>
            {Object.entries(driverPreferences).map(([key, value]) => (
              <li key={key}>{key}: {value}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DriverPassengerCompatibility;
