// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // load translation using http backend
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass i18n instance to react-i18next.
  .init({
    fallbackLng: 'en', // use en if detected lng is not available
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
