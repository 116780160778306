import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const NavBar = () => {
  const { isAuthenticated, logout, userRole } = useAuth();

  const styles = {
    nav: {
      backgroundColor: '#333',
      padding: '15px 20px',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      '@media (max-width: 768px)': {
        padding: '10px 5px' // smaller padding on mobile
      }
    },
    navContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    navLogoLink: {
      fontSize: '1.5rem',
      color: '#fff',
      fontWeight: 'bold',
      textDecoration: 'none',
      transition: 'color 0.3s ease-in-out',
      '@media (max-width: 768px)': {
        fontSize: '1rem' // smaller font size on mobile
      }
    },
    navMenu: {
      listStyle: 'none',
      display: 'flex',
      margin: 0,
      padding: 0,
      '@media (max-width: 768px)': {
        display: 'none' // hide menu items on mobile, show only when hamburger is clicked
      }
    },
    navMenuItem: {
      marginRight: '20px',
      '@media (max-width: 768px)': {
        marginRight: '10px' // smaller margin on mobile
      }
    },
    navMenuLink: {
      color: 'white',
      textDecoration: 'none',
      padding: '10px 15px',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease-in-out, transform 0.2s ease-in-out',
      '@media (max-width: 768px)': {
        padding: '8px 10px' // smaller padding on mobile
      }
    },
    logoutBtn: {
      backgroundColor: '#e74c3c',
      color: '#fff',
      padding: '10px 15px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease-in-out, transform 0.2s ease-in-out',
      '@media (max-width: 768px)': {
        padding: '8px 10px' // smaller padding on mobile
      }
    }
  };

  return (
    <nav style={styles.nav}>
      <div style={styles.navContainer}>
        <div className="nav-logo">
          <Link to="/" style={styles.navLogoLink}>TOCXI</Link>
        </div>
        <ul style={styles.navMenu}>
          {isAuthenticated ? (
            <>
              {userRole === 'USER' && (
                <>
                  <li style={styles.navMenuItem}><Link to="/Bookings" style={styles.navMenuLink}>Bookings</Link></li>
                  <li style={styles.navMenuItem}><Link to="/features" style={styles.navMenuLink}>Features</Link></li>
                  <li style={styles.navMenuItem}><Link to="/dashboard" style={styles.navMenuLink}>Dashboard</Link></li>
                  <li style={styles.navMenuItem}><Link to="/settings" style={styles.navMenuLink}>Profile & Settings</Link></li>
                  <li style={styles.navMenuItem}><Link to="/LexChat" style={styles.navMenuLink}>Chat with Jack</Link></li>
                </>
              )}
              {userRole === 'DRIVER' && (
                <>
                  <li style={styles.navMenuItem}><Link to="/drivers" style={styles.navMenuLink}>Driver Management</Link></li>
                  <li style={styles.navMenuItem}><Link to="/dashboard" style={styles.navMenuLink}>Driver Dashboard</Link></li>
                </>
              )}
              <li className="logout">
                <button style={styles.logoutBtn} onClick={logout}>Logout</button>
              </li>
            </>
          ) : (
            <>
              <li style={styles.navMenuItem}><Link to="/login" style={styles.navMenuLink}>Login</Link></li>
              <li style={styles.navMenuItem}><Link to="/register" style={styles.navMenuLink}>Register</Link></li>
              <li style={styles.navMenuItem}><Link to="/DriverRegistrationForm" style={styles.navMenuLink}>Driver Registration</Link></li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
