import React, { useEffect, useState } from 'react';

const RealTimeTrafficAvoidance = () => {
  const [currentRoute, setCurrentRoute] = useState(null);
  const [trafficAlerts, setTrafficAlerts] = useState([]);
  const [loading, setLoading] = useState(true);  // Handle loading states
  const [message, setMessage] = useState('');  // Handle custom message
  const token = localStorage.getItem('authToken');  // Get token from localStorage

  useEffect(() => {
    const fetchRouteAndAlerts = async () => {
      setLoading(true);
      setMessage(''); // Reset message

      try {
        // Fetch current route data
        const routeResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/navigation/current-route`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Pass the token in the header for authentication
            'Content-Type': 'application/json',
          },
        });

        // Check the response content type
        const contentType = routeResponse.headers.get('Content-Type');

        if (routeResponse.status === 404 || contentType.includes('text/plain')) {
          const message = await routeResponse.text();
          setMessage(message);  // Set the message from plain text response
          setCurrentRoute(null);  // No route found
        } else if (routeResponse.ok) {
          const routeData = await routeResponse.json();
          setCurrentRoute(routeData);
        } else {
          throw new Error(`Failed to fetch route: ${routeResponse.statusText}`);
        }

        // Fetch traffic alerts data
        const alertsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/navigation/traffic-alerts`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Pass the token in the header for authentication
            'Content-Type': 'application/json',
          },
        });

        if (!alertsResponse.ok) {
          throw new Error(`Failed to fetch traffic alerts: ${alertsResponse.statusText}`);
        }

        const alertsData = await alertsResponse.json();
        setTrafficAlerts(alertsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching route and traffic alerts:', error);
        setMessage('Failed to fetch traffic data. Please try again.');
        setLoading(false);
      }
    };

    fetchRouteAndAlerts();
    const intervalId = setInterval(fetchRouteAndAlerts, 30000);  // Update every 30 seconds

    return () => clearInterval(intervalId);  // Clean up interval on component unmount
  }, [token]);

  return (
    <div className="card">
      <div className="card-header">Real-Time Traffic Avoidance</div>
      <div className="card-content">
        {loading ? (
          <p>Loading real-time traffic data...</p>
        ) : (
          <>
            {message ? (
              <p>{message}</p>  // Display message if no active route is found
            ) : (
              <>
                {currentRoute ? (
                  <div>
                    <p><strong>ETA:</strong> {currentRoute.eta}</p>
                    <p><strong>Distance:</strong> {currentRoute.distance} km</p>
                  </div>
                ) : (
                  <p>No active route found.</p>
                )}
              </>
            )}

            {trafficAlerts.length > 0 ? (
              <ul>
                {trafficAlerts.map((alert, index) => (
                  <li key={index} className="text-red-500">{alert}</li>
                ))}
              </ul>
            ) : (
              <p>No traffic alerts on your route.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RealTimeTrafficAvoidance;
