// src/PrivacyPolicy.js

import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy for tocxi</h1>
            <p>Last updated: October 29, 2024</p>
            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of
                Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <h2>Interpretation and Definitions</h2>

            <h3>Interpretation</h3>
            <p>The words with initial capital letters have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h3>Definitions</h3>
            <ul>
                <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
                <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party.</li>
                <li><strong>Application:</strong> Refers to tocxi, the software program provided by the Company.</li>
                <li><strong>Company:</strong> Refers to tocxi, also referred to as "We", "Us", or "Our".</li>
                <li><strong>Cookies:</strong> Small files that are placed on Your device by a website.</li>
                <li><strong>Country:</strong> Refers to Karnataka, India.</li>
                <li><strong>Device:</strong> Any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
                <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
                <li><strong>Service:</strong> Refers to the Application or the Website or both.</li>
                <li><strong>Website:</strong> Refers to tocxi, accessible from <a href="https://www.tocxi.com" target="_blank" rel="noopener noreferrer">www.tocxi.com</a></li>
                <li><strong>You:</strong> The individual accessing or using the Service.</li>
            </ul>

            <h2>Collecting and Using Your Personal Data</h2>

            <h3>Types of Data Collected</h3>
            <p><strong>Personal Data:</strong> While using Our Service, We may ask You to provide Us with certain personally identifiable information, including but not limited to:</p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
            </ul>

            <p><strong>Usage Data:</strong> Usage Data is collected automatically when using the Service, such as IP address, browser type, and other diagnostic data.</p>

            <h3>Tracking Technologies and Cookies</h3>
            <p>We use Cookies and similar tracking technologies to track activity on Our Service and store certain information.</p>

            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li><strong>To provide and maintain our Service:</strong> including monitoring the usage of our Service.</li>
                <li><strong>To manage Your Account:</strong> to enable Your access as a registered user.</li>
                <li><strong>For business transfers:</strong> in case of a merger, sale, or transfer of assets.</li>
                <li><strong>For other purposes:</strong> such as data analysis and improving our Service.</li>
            </ul>

            <h2>Retention of Your Personal Data</h2>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.</p>

            <h2>Transfer of Your Personal Data</h2>
            <p>Your information may be transferred to — and maintained on — computers located outside of Your jurisdiction where data protection laws may differ.</p>

            <h2>Delete Your Personal Data</h2>
            <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>

            <h2>Disclosure of Your Personal Data</h2>
            <p>Under certain circumstances, the Company may disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>

            <h2>Security of Your Personal Data</h2>
            <p>The security of Your Personal Data is important to Us. While We strive to use commercially acceptable means to protect Your data, no method of transmission over the Internet is 100% secure.</p>

            <h2>Children's Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13.</p>

            <h2>Links to Other Websites</h2>
            <p>Our Service may contain links to other websites that are not operated by Us. We advise You to review the Privacy Policy of every site You visit.</p>

            <h2>Changes to this Privacy Policy</h2>
            <p>We may update Our Privacy Policy from time to time. Changes will be posted on this page.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                <li>By email: <a href="mailto:peoplepy0@gmail.com">peoplepy0@gmail.com</a></li>
                <li>By visiting this page on our website: <a href="https://www.tocxi.com">www.tocxi.com</a></li>
            </ul>
        </div>
    );
};

export default PrivacyPolicy;