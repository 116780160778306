import React from 'react';
import ScheduledRides from './ScheduledRides';
import HealthAndWellness from './HealthAndWellness';
import CommunityPointsOfInterest from './CommunityPointsOfInterest';
import './InnovativeFeatures.css';  // Import the CSS file

const InnovativeFeatures = () => {
  return (
    <div className="features-hub-container">
      <div className="features-grid">
        <div className="card">
          <div className="card-header">Scheduled Rides</div>
          <div className="card-content">
            <ScheduledRides />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Health & Wellness</div>
          <div className="card-content">
            <HealthAndWellness />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Community Points of Interest</div>
          <div className="card-content">
            <CommunityPointsOfInterest />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovativeFeatures;
