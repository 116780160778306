// src/components/Features/MoodBasedRideExperience.js
import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Label from '../ui/Label';
import Select from '../ui/Select';
import Slider from '../ui/Slider';
import { Alert } from '../ui/Alert';
import { Smile } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';  // For token authorization

const MoodBasedRideExperience = () => {
  const [mood, setMood] = useState('neutral');
  const [preferences, setPreferences] = useState({
    music: 50,
    temperature: 22,
    conversation: 'moderate',
  });
  const token = localStorage.getItem('authToken');

  const updatePreference = (key, value) => {
    setPreferences({ ...preferences, [key]: value });
  };

  const saveMoodPreferences = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/mood-preferences`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mood, preferences }),
      });

      if (response.ok) {
        Alert({ title: 'Success', description: 'Mood preferences saved successfully!' });
      } else {
        Alert({ title: 'Error', description: 'Failed to save mood preferences.' });
      }
    } catch (error) {
      console.error('Error saving mood preferences:', error);
      Alert({ title: 'Error', description: 'Failed to save mood preferences.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Smile className="inline-block mr-2" /> Mood-Based Ride Experience
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Label htmlFor="mood">Current Mood</Label>
          <Select
            id="mood"
            value={mood}
            onChange={(e) => setMood(e.target.value)}
          >
            <option value="happy">Happy</option>
            <option value="relaxed">Relaxed</option>
            <option value="neutral">Neutral</option>
            <option value="stressed">Stressed</option>
            <option value="tired">Tired</option>
          </Select>

          <Label htmlFor="music">Music Volume</Label>
          <Slider
            id="music"
            min={0}
            max={100}
            value={preferences.music}
            onChange={(value) => updatePreference('music', value)}
          />

          <Label htmlFor="temperature">Temperature (°C)</Label>
          <Slider
            id="temperature"
            min={16}
            max={28}
            value={preferences.temperature}
            onChange={(value) => updatePreference('temperature', value)}
          />

          <Label htmlFor="conversation">Conversation Level</Label>
          <Select
            id="conversation"
            value={preferences.conversation}
            onChange={(e) => updatePreference('conversation', e.target.value)}
          >
            <option value="silent">Silent</option>
            <option value="minimal">Minimal</option>
            <option value="moderate">Moderate</option>
            <option value="chatty">Chatty</option>
          </Select>

          <Button onClick={saveMoodPreferences}>Save Preferences</Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default MoodBasedRideExperience;
