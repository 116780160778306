import React from 'react';
import './Progress.css'; // Optional: For custom styling

const Progress = ({ value, max }) => {
  const percentage = (value / max) * 100;
  return (
    <div className="progress-bar">
      <div className="progress-fill" style={{ width: `${percentage}%` }}>
        {Math.round(percentage)}%
      </div>
    </div>
  );
};

export default Progress;
