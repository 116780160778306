import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Input from '../ui/Input';
import { Alert } from '../ui/Alert';
import { DollarSign } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';  // Assuming you manage user token in context

const DynamicPricing = () => {
  const [rideDetails, setRideDetails] = useState({
    pickup: '',
    dropoff: '',
    distance: 0,
    duration: 0,
  });
  const [pricing, setPricing] = useState(null);
  const token = localStorage.getItem('authToken');

  // Handle input changes
  const handleInputChange = (e) => {
    setRideDetails({ ...rideDetails, [e.target.name]: e.target.value });
  };

  // Calculate price using fetch
  const calculatePrice = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/calculate-price`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,  // Pass token in Authorization header
        },
        body: JSON.stringify(rideDetails),
      });

      if (!response.ok) {
        throw new Error('Failed to calculate price');
      }

      const data = await response.json();
      setPricing(data);  // Update the state with the received pricing data
    } catch (error) {
      console.error('Error calculating price:', error);
      Alert({ title: 'Error', description: 'Failed to calculate price. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><DollarSign className="inline-block mr-2" /> Dynamic Pricing</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Input
            name="pickup"
            placeholder="Pickup Location"
            value={rideDetails.pickup}
            onChange={handleInputChange}
          />
          <Input
            name="dropoff"
            placeholder="Dropoff Location"
            value={rideDetails.dropoff}
            onChange={handleInputChange}
          />
          <Input
            name="distance"
            type="number"
            placeholder="Estimated Distance (km)"
            value={rideDetails.distance}
            onChange={handleInputChange}
          />
          <Input
            name="duration"
            type="number"
            placeholder="Estimated Duration (minutes)"
            value={rideDetails.duration}
            onChange={handleInputChange}
          />
          <Button onClick={calculatePrice}>Calculate Price</Button>
          {pricing && (
            <div>
              <h3 className="font-bold">Price Breakdown</h3>
              <p>Base Fare: ${pricing.baseFare}</p>
              <p>Distance Charge: ${pricing.distanceCharge}</p>
              <p>Time Charge: ${pricing.timeCharge}</p>
              <p>Surge Multiplier: {pricing.surgeMultiplier}x</p>
              <p className="font-bold">Total Price: ${pricing.totalPrice}</p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default DynamicPricing;
