import React, { useState, useEffect } from 'react';
import SockJS from 'sockjs-client';  // For SockJS connection
import { Client } from '@stomp/stompjs';  // Modern Stomp client
import { toast } from 'react-toastify';

function RideUpdates({ rideId }) {
    const [rideStatus, setRideStatus] = useState('Waiting for updates...');

    useEffect(() => {
        // Create WebSocket connection
        const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws`);
        const stompClient = new Client({
            webSocketFactory: () => socket, // Use SockJS for WebSocket
            reconnectDelay: 5000,  // Auto-reconnect every 5 seconds if the connection is lost
            debug: (str) => console.log(str), // Debugging for development
        });

        stompClient.onConnect = () => {
            console.log('WebSocket Connected');
            // Subscribe to the specific topic for this ride
            stompClient.subscribe(`/topic/ride/${rideId}/status`, (message) => {
                const data = JSON.parse(message.body);
                console.log('Received:', data);
                setRideStatus(data.message);
                toast.info(data.message); // Display a toast notification with the ride status
            });
        };

        stompClient.onStompError = (error) => {
            console.log('WebSocket Error:', error);
            setRideStatus('Failed to connect. Retrying...');
        };

        // Activate the WebSocket connection
        stompClient.activate();

        // Cleanup function to disconnect WebSocket when the component unmounts or rideId changes
        return () => {
            if (stompClient.active) {
                stompClient.deactivate();
                console.log('WebSocket Disconnected');
            }
        };
    }, [rideId]); // Only reconnect if rideId changes

    return (
        <div>
            <h2>Ride Updates for Ride ID: {rideId}</h2>
            <p>Status: {rideStatus}</p>
        </div>
    );
}

export default RideUpdates;
