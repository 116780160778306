import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/Alert';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const speechSynthesis = window.speechSynthesis;

const LexChat = () => {
  const [response, setResponse] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  const idleTimeoutRef = useRef(null);

  const recognition = useRef(new SpeechRecognition());

  useEffect(() => {
    if (!SpeechRecognition) {
      setError('Speech recognition is not supported in this browser.');
      return;
    }

    recognition.current.lang = 'en-US';
    recognition.current.interimResults = false;
    recognition.current.continuous = false;

    recognition.current.onresult = event => {
      const transcript = event.results[0][0].transcript;
      console.log('You said:', transcript);
      sendCommandToLex(transcript);
    };

    recognition.current.onend = () => {
      setIsListening(false);
      console.log('Speech recognition service disconnected');
      clearIdleTimeout();
    };

    recognition.current.onerror = event => {
      console.error('Recognition error:', event.error);
      setError(`Speech recognition error: ${event.error}`);
    };

    return () => {
      recognition.current.stop();
    };
  }, []);

  const startListening = () => {
    setIsListening(true);
    recognition.current.start();
    resetIdleTimeout();
  };

  const stopListening = () => {
    recognition.current.stop();
    setIsListening(false);
    clearIdleTimeout();
  };

  const sendCommandToLex = async (command) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/lex/interact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: '12345', message: command }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Error communicating with Lex');
      setResponse(data.message); // Ensure that the response is expected to be an object with a message property
      speakResponse(data.message);
    } catch (error) {
      console.error('Error interacting with Lex:', error);
      setError('Failed to process command. Please try again.');
      speakResponse('Failed to process command. Please try again.');
    }
  };

  const speakResponse = (text) => {
    if (!speechSynthesis || !text) return;
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
    utterance.onend = () => {
      if (!isListening) startListening(); // Restart listening only if it was previously stopped
    };
  };

  const resetIdleTimeout = () => {
    clearIdleTimeout();
    idleTimeoutRef.current = setTimeout(() => {
      console.log('No speech detected, stopping listening due to inactivity.');
      stopListening();
    }, 30000); // 30 seconds idle timeout
  };

  const clearIdleTimeout = () => {
    if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle>NiceCab Voice Assistant</CardTitle>
      </CardHeader>
      <CardContent>
        {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
        <Button onClick={startListening} disabled={isListening}>
          {isListening ? <Mic className="h-5 w-5 text-green-500" /> : <MicOff className="h-5 w-5" />}
          {isListening ? 'Listening...' : 'Start Listening'}
        </Button>
        {response && <Alert><AlertDescription>Response: {response}</AlertDescription></Alert>}
      </CardContent>
    </Card>
  );
};

export default LexChat;
