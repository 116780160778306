import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { toast } from 'react-toastify';

const DriverNotifications = ({ driverId, onRideAccepted }) => {
  const [rideRequest, setRideRequest] = useState(null);

  useEffect(() => {
    const notificationSound = new Audio('/cab2.wav'); // Preload sound

    const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws`);
    const client = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 5000,
      debug: (str) => console.log(str),
    });

    client.onConnect = () => {
      console.log('Connected to WebSocket');

      // Subscribe to driver-specific notifications
client.subscribe(`/topic/driver/${driverId}/notifications`, (message) => {
  const request = JSON.parse(message.body);
  console.log('Full ride request object:', request); // Log full request
  setRideRequest(request);
  toast.info(`New ride request from ${request.pickupLocation} to ${request.dropoffLocation}`);
  notificationSound.play();
  onRideAccepted(request);
});


      // Subscribe to general available drivers topic
      client.subscribe('/topic/availableDrivers', (message) => {
        const request = JSON.parse(message.body);
        setRideRequest(request);
        console.log('New ride request for available drivers:', request);
        notificationSound.play();
        onRideAccepted(request);
      });
    };

    client.onStompError = (frame) => {
      console.error('STOMP Error:', frame.headers['message'], frame.body);
      toast.error('WebSocket connection error');
    };

    client.activate();

    return () => {
      client.deactivate();
    };
  }, [driverId, onRideAccepted]);

  if (!rideRequest) return null;

  const handleResponse = (accepted) => {
    const driverResponse = {
      rideId: rideRequest.rideId,
      driverId: driverId,
      accepted,
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/response/${rideRequest.rideId}/${accepted}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(driverResponse),
    })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to process response');
        return res.json();
      })
      .then((data) => {
        toast.success(data.message || 'Response processed');
        setRideRequest(null);
      })
      .catch((error) => {
        console.error('Error responding to ride request:', error);
        toast.error('Error processing response');
      });
  };

  return (
    <div className="ride-request-popup">
      <h2>New Ride Request</h2>
      <p><strong>Pickup:</strong> {rideRequest.pickupLocation}</p>
      <p><strong>Dropoff:</strong> {rideRequest.dropoffLocation}</p>
      <div className="button-group">
        <button onClick={() => handleResponse(true)} className="accept-btn">
          Accept
        </button>
        <button onClick={() => handleResponse(false)} className="reject-btn">
          Reject
        </button>
      </div>
    </div>
  );
};

export default DriverNotifications;
