import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Popup } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RideBookingForm.css';
import VoiceCommand from '../voice command/VoiceCommand';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

const RideBookingForm = ({ onRideBooked }) => {
  const [pickupCoords, setPickupCoords] = useState(null);
  const [dropoffCoords, setDropoffCoords] = useState(null);
  const [pickupQuery, setPickupQuery] = useState('');
  const [dropoffQuery, setDropoffQuery] = useState('');
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [dropoffSuggestions, setDropoffSuggestions] = useState([]);
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const [vehicleType, setVehicleType] = useState('sedan');
  const mapRef = useRef(null);
  const routingControlRef = useRef(null);
  const provider = new OpenStreetMapProvider();
    const [isVoiceCommand, setIsVoiceCommand] = useState(false); // Track if it's a voice command
  const [availableDrivers, setAvailableDrivers] = useState([]); // New: For displaying available drivers

  const [rideStatus, setRideStatus] = useState(null); // Track ride status
  const [rideId, setRideId] = useState(null); // Store the rideId when booking
  const [isWaitingForDriver, setIsWaitingForDriver] = useState(false); // Waiting state
const [userLocation, setUserLocation] = useState(null); // Store user's location
const MAX_DISTANCE = 100; // Maximum distance in kilometers
const [isCanceling, setIsCanceling] = useState(false); // Cancellation state


  const pickupIcon = L.icon({
    iconUrl: '/icons/pickup.png',
    iconSize: [32, 32],
  });

  const dropoffIcon = L.icon({
    iconUrl: '/icons/dropoff.png',
    iconSize: [32, 32],
  });
  // Define driver icon
  const driverIcon = L.icon({
    iconUrl: '/icons/sedan.png',
    iconSize: [32, 32],
  });

const fetchAvailableDrivers = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/drivers/available`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch available drivers');
    }
    const drivers = await response.json();
    console.log("Available drivers: ", drivers);
    setAvailableDrivers(drivers);
  } catch (error) {
    console.error('Error fetching available drivers:', error);
  }
};


    // Fetch drivers when component mounts
    useEffect(() => {
      fetchAvailableDrivers();
    }, []);

const fetchLocationSuggestions = async (query, setSuggestions) => {
    if (query.length < 3) {
        console.log('Query too short:', query);  // Log when query is too short
        return;
    }

    console.log('Fetching suggestions for:', query);  // Log the query being fetched

    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`);
        const results = await response.json();
        console.log('Location suggestions received:', results);  // Log the results

        setSuggestions(results);
        console.log('Suggestions state updated:', results);  // Log state update after fetching
    } catch (error) {
        console.error('Error fetching suggestions:', error);  // Log errors during fetch
        toast.error('Error fetching location suggestions.');
    }
};


const handlePickupSelect = (lat, lon, display_name) => {
  // Calculate distance from user's location to selected pickup location
  if (userLocation) {
    const distanceFromUser = calculateDistance(userLocation[0], userLocation[1], lat, lon);

    if (distanceFromUser > MAX_DISTANCE) {
      toast.error('Pickup location must be within 100 km of your current location.');
      return; // Prevent selection if location is out of range
    }
  }

  setPickupCoords([lat, lon]);
  setPickupQuery(display_name);
  setPickupSuggestions([]);
  mapRef.current?.flyTo([lat, lon], 18);
};

// Haversine formula to calculate distance between two points
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Return distance in kilometers
};

  const handleDropoffSelect = (lat, lon, display_name) => {
    setDropoffCoords([lat, lon]);
    setDropoffQuery(display_name);
    setDropoffSuggestions([]);
    mapRef.current?.flyTo([lat, lon], 18);
  };

const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);  // Store user location in state

        // Get the formatted address for the current location
        const address = await reverseGeocode(latitude, longitude);
        setPickupQuery(address);  // Set the address as the pickup location

        // Set pickup coordinates for the map
        setPickupCoords([latitude, longitude]);
        mapRef.current?.flyTo([latitude, longitude], 18);
      },
      (error) => {
        toast.error('Unable to fetch current location.');
        console.error('Error fetching current location:', error);
      },
      { enableHighAccuracy: true, timeout: 5000 }
    );
  } else {
    toast.error('Geolocation is not supported by this browser.');
  }
};

      // On component mount, set the pickup location to the user's current location by default
      useEffect(() => {
        getCurrentLocation();  // Fetch current location when the component mounts
      }, []);


const reverseGeocode = async (lat, lon) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
    );
    const data = await response.json();
    return data.display_name;  // Return the formatted address
  } catch (error) {
    console.error('Error in reverse geocoding:', error);
    toast.error('Failed to fetch your location address');
    return 'Unknown Location';
  }
};



  const handleVoiceSelectPickup = (optionNumber) => {
    const index = optionNumber - 1; // Convert to 0-based index
    if (pickupSuggestions[index]) {
      const selected = pickupSuggestions[index];
      handlePickupSelect(selected.lat, selected.lon, selected.display_name);
    } else {
      toast.error('Invalid pickup option selected.');
    }
  };

  const handleVoiceSelectDropoff = (optionNumber) => {
    const index = optionNumber - 1; // Convert to 0-based index
    if (dropoffSuggestions[index]) {
      const selected = dropoffSuggestions[index];
      handleDropoffSelect(selected.lat, selected.lon, selected.display_name);
    } else {
      toast.error('Invalid dropoff option selected.');
    }
  };
// WebSocket for tracking ride status
useEffect(() => {
  if (rideId) {
    const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws`);
    const stompClient = new Client({
      webSocketFactory: () => socket,
      onConnect: () => {
        stompClient.subscribe(`/topic/ride/${rideId}/status`, (message) => {
          const status = message.body;
          setRideStatus(status);
          setIsWaitingForDriver(false); // Stop waiting when we get a response

          if (status === 'ACCEPTED') {
            toast.success('A driver has accepted your ride!');
          } else if (status === 'REJECTED') {
            toast.error('No driver accepted the ride. Please try again.');
          } else if (status === 'NO_DRIVER_AVAILABLE') {
            toast.error('No drivers available. Please try again.');
          }
        });
      },
      reconnectDelay: 5000,
      onStompError: (frame) => {
        console.error('STOMP error:', frame.headers['message'], frame.body);
      },
    });

    stompClient.activate();

    return () => {
      if (stompClient) stompClient.deactivate(); // Clean up WebSocket connection
    };
  }
}, [rideId]);
const handleRetryBooking = () => {
  setRideId(null); // Clear the rideId
  setRideStatus(null); // Clear the ride status
  setIsWaitingForDriver(false); // Stop the waiting state
  toast.info("Retry booking your ride.");
};


const handleCancelBooking = async () => {
  if (!rideId) return;

  setIsCanceling(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/${rideId}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    });

    if (response.ok) {
      toast.success('Ride canceled successfully.');
      setRideStatus(null);
      setRideId(null);
      setIsWaitingForDriver(false);
    } else {
      toast.error('Failed to cancel ride.');
    }
  } catch (error) {
    toast.error('Error canceling the ride.');
  } finally {
    setIsCanceling(false);
  }
};


useEffect(() => {
  if (pickupCoords && dropoffCoords && mapRef.current) {
    if (!routingControlRef.current) {
      routingControlRef.current = L.Routing.control({
        waypoints: [L.latLng(pickupCoords), L.latLng(dropoffCoords)],
        routeWhileDragging: true,
      }).addTo(mapRef.current);
    } else {
      routingControlRef.current.setWaypoints([L.latLng(pickupCoords), L.latLng(dropoffCoords)]);
    }

    const distanceInKm = mapRef.current.distance(pickupCoords, dropoffCoords) / 1000;
    const durationInMinutes = (distanceInKm / 50) * 60;
    const cost = (distanceInKm * 5).toFixed(2);
    setDistance(distanceInKm.toFixed(2));
    setDuration(durationInMinutes.toFixed(2));
    setEstimatedCost(`₹${cost}`);
  }

  return () => {
    if (routingControlRef.current) {
      routingControlRef.current.getPlan().setWaypoints([]);
      routingControlRef.current.remove();
      routingControlRef.current = null;
    }
  };
}, [pickupCoords, dropoffCoords]);



const handleBooking = async () => {
  if (!pickupCoords || !dropoffCoords) {
    toast.warn("Please select both pickup and dropoff locations.");
    return;
  }

  if (!userLocation) {
    toast.error("Unable to access your location. Please enable location services.");
    return;
  }

  // Validate pickup and dropoff distance from userLocation
  const distanceFromUserToPickup = calculateDistance(
    userLocation[0],
    userLocation[1],
    pickupCoords[0],
    pickupCoords[1]
  );

  const distanceFromUserToDropoff = calculateDistance(
    userLocation[0],
    userLocation[1],
    dropoffCoords[0],
    dropoffCoords[1]
  );

  // Check if either pickup or dropoff location is beyond the MAX_DISTANCE
  if (distanceFromUserToPickup > MAX_DISTANCE) {
    toast.error('Pickup location must be within 100 km of your current location.');
    return; // Prevent booking if pickup location is out of range
  }

  if (distanceFromUserToDropoff > MAX_DISTANCE) {
    toast.error('Dropoff location must be within 100 km of your current location.');
    return; // Prevent booking if dropoff location is out of range
  }

  // Proceed with booking logic
  setIsBooking(true);
const bookingData = {
  pickupLocation: pickupQuery,  // Address (Display Name)
  dropLocation: dropoffQuery,   // Address (Display Name)
  vehicleType,
  distanceInKm: parseFloat(distance),
  durationInMinutes: parseFloat(duration),
  pickupLatitude: pickupCoords[0],  // Pickup latitude (Actual Coordinates)
  pickupLongitude: pickupCoords[1], // Pickup longitude (Actual Coordinates)
  dropoffLatitude: dropoffCoords[0], // Dropoff latitude (Actual Coordinates)
  dropoffLongitude: dropoffCoords[1], // Dropoff longitude (Actual Coordinates)
};


  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/book`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(bookingData),
    });

    if (response.ok) {
      const data = await response.json();
      toast.success('Searching for available drivers...'); // Show searching message when booking starts
      onRideBooked(data.rideId);  // Pass rideId if needed
      setRideId(data.rideId); // Store the ride ID
      setIsWaitingForDriver(true); // Show "waiting for driver" state
      if (stopListening) stopListening();  // Stop voice recognition after booking
    } else {
      const errorData = await response.json();
      toast.error('Booking failed: ' + errorData.message);
    }
  } catch (error) {
    toast.error('Error during booking');
    console.error(error);
  } finally {
    setIsBooking(false);
  }
};


useEffect(() => {
  if (rideId) {
    const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws`);
    const stompClient = new Client({
      webSocketFactory: () => socket,
      onConnect: () => {
        stompClient.subscribe(`/topic/ride/${rideId}/status`, (message) => {
          const status = message.body;
          setRideStatus(status);
          setIsWaitingForDriver(false); // Stop waiting when we get a response

          if (status === 'SEARCHING') {
            toast.info('Searching for drivers...');
          } else if (status === 'ACCEPTED') {
            toast.success('A driver has accepted your ride!');
            onRideBooked(rideId); // Call your callback to update the UI if needed
          } else if (status === 'REJECTED') {
            toast.error('No driver accepted the ride. Please try again.');
            handleRetryBooking(); // Reset the state to allow retrying the booking
          } else if (status === 'NO_DRIVER_AVAILABLE') {
            toast.error('No drivers available. Please try again.');
            handleRetryBooking(); // Reset the state to allow retrying the booking
          }
        });
      },
      reconnectDelay: 5000,
      onStompError: (frame) => {
        console.error('STOMP error:', frame.headers['message'], frame.body);
      },
    });

    stompClient.activate();

    return () => {
      if (stompClient) stompClient.deactivate(); // Clean up WebSocket connection
    };
  }
}, [rideId]);


// Timeout after 60 seconds
useEffect(() => {
  if (isWaitingForDriver) {
    const timeout = setTimeout(() => {
      if (!rideStatus) {
        toast.error("No drivers available at the moment. Please try again later.");
        setIsWaitingForDriver(false); // Stop waiting
      }
    }, 60000); // Wait for 60 seconds before notifying

    return () => clearTimeout(timeout); // Cleanup timeout
  }
}, [isWaitingForDriver, rideStatus]);




  let stopListening = null; // Define stopListening in the component scope
const handleVoiceCommand = (command) => {
  const lowerCaseCommand = command.toLowerCase();
  console.log('Received voice command:', lowerCaseCommand);  // Debug log for the full command

  // Handle selection of pickup and dropoff options based on voice
  const pickupRegex = /select(?: pickup)?(?: option)? (\d+)/;
  const dropoffRegex = /select(?: dropoff)?(?: option)? (\d+)/;

  const pickupMatch = lowerCaseCommand.match(pickupRegex);
  const dropoffMatch = lowerCaseCommand.match(dropoffRegex);

  if (pickupMatch) {
    const optionNumber = parseInt(pickupMatch[1]);
    if (!isNaN(optionNumber)) {
      handleVoiceSelectPickup(optionNumber);
    }
  } else if (dropoffMatch) {
    const optionNumber = parseInt(dropoffMatch[1]);
    if (!isNaN(optionNumber)) {
      handleVoiceSelectDropoff(optionNumber);
    }
  } else if (lowerCaseCommand.includes('pickup') || lowerCaseCommand.includes('pick up') || lowerCaseCommand.includes('pick up location')|| lowerCaseCommand.includes('pickup location')) {
    // Handle pickup location search
    const location = lowerCaseCommand
      .replace('pickup', '')
      .replace('pick up', '')
      .replace('pick up location', '')
      .replace('pickup location', '')
      .trim();
    if (location) {
      setPickupQuery(location);
      console.log('Fetching suggestions for pickup location:', location);  // Log the query being sent
      fetchLocationSuggestions(location, setPickupSuggestions);
    }
  } else if (lowerCaseCommand.includes('drop')) {
    // Handle dropoff location search
    const location = lowerCaseCommand.replace('drop', '').replace('drop location', '').trim();
    if (location) {
      setDropoffQuery(location);
      console.log('Fetching suggestions for dropoff location:', location);  // Log the query being sent
      fetchLocationSuggestions(location, setDropoffSuggestions);
    }
  } else if (lowerCaseCommand.includes('book')) {
    console.log('Booking command recognized. Proceeding to book.');  // Log when the booking command is recognized
    handleBooking();
  } else {
    toast.error('Invalid command.');
  }
};

// Timeout for waiting for a driver to accept
useEffect(() => {
  if (isWaitingForDriver) {
    const timeout = setTimeout(() => {
      if (!rideStatus) {
        toast.error('No drivers available. Please try again later.');
        setIsWaitingForDriver(false); // Stop waiting
      }
    }, 60000); // Wait for 60 seconds

    return () => clearTimeout(timeout);
  }
}, [isWaitingForDriver, rideStatus]);


// Updated useEffect to handle no suggestions found scenario
useEffect(() => {
  console.log('pickupSuggestions updated:', pickupSuggestions);
  if (isVoiceCommand && pickupSuggestions.length > 0) {
    // Automatically select the first suggestion if it's a voice command
    console.log('Automatically selecting the first pickup suggestion:', pickupSuggestions[0].display_name);
    handlePickupSelect(pickupSuggestions[0].lat, pickupSuggestions[0].lon, pickupSuggestions[0].display_name);
    setIsVoiceCommand(false); // Reset flag after automatic selection
  }
}, [pickupSuggestions, isVoiceCommand]);

useEffect(() => {
  console.log('dropoffSuggestions updated:', dropoffSuggestions);
  if (isVoiceCommand && dropoffSuggestions.length > 0) {
    // Automatically select the first suggestion if it's a voice command
    console.log('Automatically selecting the first dropoff suggestion:', dropoffSuggestions[0].display_name);
    handleDropoffSelect(dropoffSuggestions[0].lat, dropoffSuggestions[0].lon, dropoffSuggestions[0].display_name);
    setIsVoiceCommand(false); // Reset flag after automatic selection
  }
}, [dropoffSuggestions, isVoiceCommand]);

  return (
    <div className="ride-booking-form-container">
      <div className="ride-booking-left card">
        <h2>Book Your Ride</h2>

        <div className="form-group">
          <label>Pickup Location</label>
          <input
            type="text"
            value={pickupQuery}
            onChange={(e) => {
              setPickupQuery(e.target.value);
              fetchLocationSuggestions(e.target.value, setPickupSuggestions);
            }}
            placeholder="Enter pickup location..."
          />
          {pickupSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {pickupSuggestions.map((suggestion) => (
                <li key={suggestion.place_id} onClick={() => handlePickupSelect(suggestion.lat, suggestion.lon, suggestion.display_name)}>
                  {suggestion.display_name}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="form-group">
          <label>Dropoff Location</label>
          <input
            type="text"
            value={dropoffQuery}
            onChange={(e) => {
              setDropoffQuery(e.target.value);
              fetchLocationSuggestions(e.target.value, setDropoffSuggestions);
            }}
            placeholder="Enter dropoff location..."
          />
          {dropoffSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {dropoffSuggestions.map((suggestion) => (
                <li key={suggestion.place_id} onClick={() => handleDropoffSelect(suggestion.lat, suggestion.lon, suggestion.display_name)}>
                  {suggestion.display_name}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="form-group">
          <label>Vehicle Type</label>
          <select value={vehicleType} onChange={(e) => setVehicleType(e.target.value)}>
            <option value="sedan">Sedan</option>
            <option value="suv">SUV</option>
            <option value="hatchback">Hatchback</option>
          </select>
        </div>

        {estimatedCost && (
          <p className="fare-estimate">Estimated Fare: {estimatedCost} | Distance: {distance} km | Duration: {duration} mins</p>
        )}
      {isWaitingForDriver && <p>Waiting for a driver to accept your ride...</p>}
      {rideStatus && <p>Ride Status: {rideStatus}</p>}
        <button onClick={handleBooking} disabled={!pickupCoords || !dropoffCoords || isBooking}>
          {isBooking ? 'Booking...' : 'Confirm Booking'}
        </button>
{rideStatus === 'NO_DRIVER_AVAILABLE' && (
  <button onClick={handleRetryBooking}>
    Retry Booking
  </button>
)}

        <VoiceCommand
          onCommand={handleVoiceCommand}
          setStopListeningRef={(stopFn) => { stopListening = stopFn; }} // Store the stop function
        />
        {isBooking && <div className="loading-spinner">Booking in progress...</div>}
        {isWaitingForDriver && (
          <>
            <p>Waiting for a driver to accept your ride...</p>
            <button onClick={handleCancelBooking} disabled={isCanceling}>
              {isCanceling ? 'Canceling...' : 'Cancel Booking'}
            </button>
          </>
        )}


      </div>

       <div className="ride-booking-right card">
              <MapContainer
                center={[12.9716, 77.5946]} // Bangalore coordinates
                zoom={13}
                style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                ref={mapRef}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; OpenStreetMap contributors"
                />

                {pickupCoords && <Marker position={pickupCoords} icon={pickupIcon} />}
                {dropoffCoords && <Marker position={dropoffCoords} icon={dropoffIcon} />}
                {pickupCoords && dropoffCoords && <Polyline positions={[pickupCoords, dropoffCoords]} color="blue" />}

                {/* Display available drivers on the map */}
                {availableDrivers.map((driver) => (
                  <Marker key={driver.id} position={[driver.latitude, driver.longitude]} icon={driverIcon}>
                    <Popup>
                      <b>Driver:</b> {driver.name} <br />
                      <b>Vehicle:</b> {driver.vehicleModel}
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </div>
    </div>
  );
};

export default RideBookingForm;
