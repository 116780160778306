import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './DriverRegistrationForm.css';

const DriverRegistrationForm = ({ onRegister }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState(''); // State for email
  const [licenseNumber, setLicenseNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleRegisterDriver = async () => {
    // Basic validation
    if (!name || !email || !licenseNumber || !phoneNumber || !vehicleModel || !vehicleNumber || !password || !confirmPassword) {
      toast.error('Please fill in all fields');
      return;
    }

    // Ensure passwords match
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    // Check if browser supports geolocation
    if (navigator.geolocation) {
      setLoading(true); // Set loading to true
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const driverData = {
            name,
            email,  // Include the email in the registration data
            licenseNumber,
            phoneNumber,
            vehicleModel,
            vehicleNumber,
            password,
            latitude,
            longitude,
          };

          // POST request to register driver
          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register-driver`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(driverData),
            });

            if (response.ok) {
              const data = await response.json();
              toast.success(`Driver registered successfully! ID: ${data.id}`);
              onRegister(data.id); // Optionally pass the driver ID to the parent component
              // Clear form fields after successful registration
              setName('');
              setEmail('');
              setLicenseNumber('');
              setPhoneNumber('');
              setVehicleModel('');
              setVehicleNumber('');
              setPassword('');
              setConfirmPassword('');
            } else {
              toast.error('Failed to register driver');
            }
          } catch (error) {
            toast.error(`Error registering driver: ${error.message}`);
          } finally {
            setLoading(false); // Set loading to false after completion
          }
        },
        (error) => {
          toast.error(`Error getting location: ${error.message}`);
          setLoading(false); // Set loading to false on error
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <div className="driver-registration-form">
      <h2>Register New Driver</h2>
      <div className="form-group">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Driver Name"
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={licenseNumber}
          onChange={(e) => setLicenseNumber(e.target.value)}
          placeholder="License Number"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={vehicleModel}
          onChange={(e) => setVehicleModel(e.target.value)}
          placeholder="Vehicle Model"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={vehicleNumber}
          onChange={(e) => setVehicleNumber(e.target.value)}
          placeholder="Vehicle Number"
        />
      </div>
      <div className="form-group">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
      </div>
      <div className="form-group">
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
        />
      </div>
      <button onClick={handleRegisterDriver} disabled={loading}>
        {loading ? 'Registering...' : 'Register Driver'}
      </button>
    </div>
  );
};

export default DriverRegistrationForm;
