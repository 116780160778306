import React, { useState } from 'react';
import RideBookingForm from './RideBookingForm';
import BookingHistory from './BookingHistory';
import LiveTracking from './LiveTracking';
import RideSharing from './RideSharing';
import './Bookings.css';  // Add any custom styles here
import PreferredDrivers from './PreferredDrivers';
import { FaGripLines } from 'react-icons/fa'; // FontAwesome icon for the handle

function Bookings() {
  const [activeTab, setActiveTab] = useState('book-ride');
  const [selectedRideId, setSelectedRideId] = useState(null); // Track the selected ride ID
  const [isSharing, setIsSharing] = useState(false); // Track ride sharing status
  const [sidebarVisible, setSidebarVisible] = useState(true); // Toggle sidebar visibility

  const renderTabContent = () => {
    switch (activeTab) {
      case 'book-ride':
        return <RideBookingForm onRideBooked={handleRideBooked} />;
      case 'booking-history':
        return <BookingHistory onRideSelect={handleRideSelect} />;
      case 'live-tracking':
        if (selectedRideId) return <LiveTracking rideId={selectedRideId} />;
        return <p>Select a ride from history to track.</p>;
      case 'ride-sharing':
        return <RideSharing
                  bookingDetails={{ id: selectedRideId }} // Pass the selected ride ID as booking details
                  onShareRide={handleShareRide}
                  onCancelShare={handleCancelShare}
               />;
      case 'preferred-drivers':
        return <PreferredDrivers />;
      default:
        return <RideBookingForm onRideBooked={handleRideBooked} />;
    }
  };

  // Callback when a ride is booked, auto-switch to live tracking
  const handleRideBooked = (rideId) => {
    setSelectedRideId(rideId);
    setActiveTab('live-tracking');
  };

  // Callback when a ride is selected from booking history
  const handleRideSelect = (rideId) => {
    setSelectedRideId(rideId);
    setActiveTab('live-tracking');
  };

  // Handle starting ride sharing
  const handleShareRide = () => {
    setIsSharing(true);
  };

  // Handle canceling ride sharing
  const handleCancelShare = () => {
    setIsSharing(false);
  };

  return (
    <div className={`bookings-page ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      {/* Sidebar Handle */}
      <div className={`sidebar-handle ${sidebarVisible ? 'open' : 'closed'}`} onClick={() => setSidebarVisible(!sidebarVisible)}>
        <FaGripLines />
      </div>

      <nav className={`vertical-nav ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <button className={activeTab === 'book-ride' ? 'active' : ''} onClick={() => setActiveTab('book-ride')}>Book Ride</button>
        <button className={activeTab === 'booking-history' ? 'active' : ''} onClick={() => setActiveTab('booking-history')}>Booking History</button>
        <button className={activeTab === 'live-tracking' ? 'active' : ''} onClick={() => setActiveTab('live-tracking')} disabled={!selectedRideId}>
          Live Tracking
        </button>
        <button className={activeTab === 'ride-sharing' ? 'active' : ''} onClick={() => setActiveTab('ride-sharing')} disabled={!selectedRideId || isSharing}>
          Ride Sharing
        </button>
        <button className={activeTab === 'preferred-drivers' ? 'active' : ''} onClick={() => setActiveTab('preferred-drivers')}>Preferred Drivers</button>
      </nav>

      <div className={`tab-content ${sidebarVisible ? 'with-sidebar' : 'full-width'}`}>
        {renderTabContent()}
      </div>
    </div>
  );
}

export default Bookings;
