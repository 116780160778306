import React, { useState } from 'react';
import './Support.css';

const Support = () => {
  const [issue, setIssue] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // API call to send support request can go here
    setSubmitted(true);
  };

  if (submitted) {
    return <div className="support-confirmation">Thank you! Your issue has been submitted.</div>;
  }

  return (
    <div className="support-container">
      <h2>Contact Support</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="issue">Describe your issue</label>
        <textarea
          id="issue"
          value={issue}
          onChange={(e) => setIssue(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Support;
