import React, { useState, useEffect, useRef } from 'react';
import './VoiceCommand.css';

const VoiceCommand = ({ onCommand, setStopListeningRef }) => {
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const [transcript, setTranscript] = useState('');

  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true; // Set to true for continuous listening
      recognition.interimResults = false; // Only show final results
      recognition.lang = 'en-IN'; // Set language

      recognitionRef.current = recognition;

      recognition.onstart = () => {
        setIsListening(true);
        console.log('Voice recognition started.');
      };

      recognition.onresult = (event) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        setTranscript(transcript);
        onCommand(transcript); // Send the transcript to the parent component
        console.log('Recognized words:', transcript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        console.log('Voice recognition stopped.');
        // Restart the recognition if it is still set to listen
        if (isListening) {
          recognition.start();
          console.log('Restarting recognition for continuous listening...');
        }
      };

      // Pass stopListening function to the parent component
      if (setStopListeningRef) {
        setStopListeningRef(() => recognition.stop.bind(recognition));
      }
    } else {
      console.warn('Browser does not support speech recognition.');
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, [onCommand, setStopListeningRef, isListening]);

  const startListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.start();
      console.log('Started listening continuously.');
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      console.log('Stopped listening.');
      setIsListening(false); // Stop the listening state
    }
  };

  return (
    <div className="voice-command-container">
      <button onClick={isListening ? stopListening : startListening}>
        {isListening ? 'Stop Voice Command' : 'Start Voice Command'}
      </button>
      {transcript && <p>You said: {transcript}</p>}
    </div>
  );
};

export default VoiceCommand;
