import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaCar, FaEdit, FaSave } from 'react-icons/fa';
import './Profile.css';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState({
    username: "",
    email: "",
    fullName: "",
    phone: "",
    address: "",
    vehicleModel: "",
    vehiclePlate: "",
    profilePicture: "/icons/taxi.png" // Default profile picture
  });

  const [loading, setLoading] = useState(true); // For showing loading state
  const [error, setError] = useState(null); // To handle errors
  const [newProfilePicture, setNewProfilePicture] = useState(null); // Store new uploaded image

  // Fetch profile data from API when component mounts
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProfile(data); // Ensure profile data is set correctly, including profilePicture
          setLoading(false);
        } else {
          throw new Error('Failed to fetch profile data');
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile data');
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  // Handle profile data changes
  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  // Handle profile image upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfilePicture(file); // Store the uploaded file
    }
  };

  // Handle profile submission (update profile)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');

      const formData = new FormData();
      formData.append('username', profile.username);
      formData.append('email', profile.email);
      formData.append('fullName', profile.fullName);
      formData.append('phone', profile.phone);
      formData.append('address', profile.address);
      formData.append('vehicleModel', profile.vehicleModel);
      formData.append('vehiclePlate', profile.vehiclePlate);

      if (newProfilePicture) {
        formData.append('profilePicture', newProfilePicture); // Append new profile picture if uploaded
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/update`, {
        method: 'PUT', // Use PUT for updating the profile
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData, // Send the updated profile data with FormData
      });

      if (response.ok) {
        const updatedProfile = await response.json();
        setIsEditing(false); // Exit edit mode after successful update
        setProfile(updatedProfile); // Update the profile state with the new data
      } else {
        throw new Error('Failed to update profile data');
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile data');
    }
  };

  // Prevent form submission on "Edit" button click
  const handleEdit = () => {
    setIsEditing(true); // Only enable edit mode
  };

  if (loading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="profile-container">
      <h1>My Profile</h1>
      <div className="profile-content">
        <div className="profile-picture">
          <img
            src={profile.profilePicture || '/icons/default-profile.png'} // Fallback to default if profilePicture is null
            alt="Profile"
          />
          {isEditing && (
            <div>
              <button className="change-picture-btn">Change Picture</button>
              <input type="file" onChange={handleFileUpload} />
            </div>
          )}
        </div>
        <form className="profile-details" onSubmit={handleSubmit}>
          <div className="profile-field">
            <FaUser className="field-icon" />
            <label>Username</label>
            <input type="text" name="username" value={profile.username} disabled />
          </div>
          <div className="profile-field">
            <FaEnvelope className="field-icon" />
            <label>Email</label>
            <input type="email" name="email" value={profile.email} disabled />
          </div>
          <div className="profile-field">
            <FaUser className="field-icon" />
            <label>Full Name</label>
            <input
              type="text"
              name="fullName"
              value={profile.fullName}
              onChange={handleChange}
              disabled={!isEditing} // Enable editing only when isEditing is true
            />
          </div>
          <div className="profile-field">
            <FaPhone className="field-icon" />
            <label>Phone</label>
            <input
              type="tel"
              name="phone"
              value={profile.phone}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaMapMarkerAlt className="field-icon" />
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={profile.address}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaCar className="field-icon" />
            <label>Vehicle Model</label>
            <input
              type="text"
              name="vehicleModel"
              value={profile.vehicleModel}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaCar className="field-icon" />
            <label>Vehicle Plate</label>
            <input
              type="text"
              name="vehiclePlate"
              value={profile.vehiclePlate}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          {isEditing ? (
            <button type="submit" className="save-btn">
              <FaSave /> Save Changes
            </button>
          ) : (
            <button
              type="button"
              className="edit-btn"
              onClick={handleEdit} // Enable editing mode
            >
              <FaEdit /> Edit Profile
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Profile;
