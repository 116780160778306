// src/DeleteAccount.js

import React from 'react';
import './DeleteAccount.css';

const DeleteAccount = () => {
    return (
        <div className="delete-account-container">
            <h1>Delete Your Account</h1>
            <p>To delete your TOCXI account and all associated data, please contact our support team.</p>
            <p>Send an email to <a href="mailto:support@tocxi.com">support@tocxi.com</a> with the subject line "Account Deletion Request" and include the following information:</p>
            <ul>
                <li>Your registered email address</li>
                <li>Your full name</li>
                <li>A statement requesting the deletion of your account and associated data</li>
            </ul>
            <p>Once we receive your email, our team will process the deletion request. All your data will be permanently removed from our servers within 30 days of confirming your request.</p>
            <p>If you need further assistance, feel free to contact us at <a href="mailto:support@tocxi.com">support@tocxi.com</a>.</p>
        </div>
    );
};

export default DeleteAccount;
