import React, { useState } from 'react';
import './Promotions.css';

const Promotions = () => {
  const [promoCode, setPromoCode] = useState('');
  const [applied, setApplied] = useState(false);

  const applyPromoCode = (e) => {
    e.preventDefault();
    // Logic to validate promo code
    setApplied(true);
  };

  return (
    <div className="promotions-container">
      <h2>Current Promotions</h2>
      <div className="promo-offers">
        <p><strong>Get 10% off your next ride</strong> - Use code: NEXT10</p>
        <p><strong>Refer a friend</strong> - Get $5 off your next ride for every friend who signs up!</p>
      </div>

      <h3>Have a promo code?</h3>
      <form onSubmit={applyPromoCode}>
        <input
          type="text"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder="Enter promo code"
          required
        />
        <button type="submit">Apply Promo Code</button>
      </form>

      {applied && <div className="promo-confirmation">Promo code applied successfully!</div>}
    </div>
  );
};

export default Promotions;
