import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import Select from '../ui/Select';
import { Alert } from '../ui/Alert';
import { Heart } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';  // Assuming you have a token management context

const HealthAndWellness = () => {
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState('');
  const [exerciseDetails, setExerciseDetails] = useState(null);  // State to store the exercise details
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    fetchExercises();
  }, []);

  const fetchExercises = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/wellness/exercises`, {  // Use localhost URL
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass token in header for authentication
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch exercises');
      }

      const data = await response.json();
      setExercises(data);
    } catch (error) {
      console.error('Error fetching exercises:', error);
      Alert({ title: 'Error', description: 'Failed to fetch exercises. Please try again.' });
    }
  };

  const handleExerciseSelect = async (exerciseId) => {
    setSelectedExercise(exerciseId);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/wellness/exercise/${exerciseId}`, {  // Use localhost URL
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass token for authentication
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch exercise details');
      }

      const data = await response.json();
      setExerciseDetails(data);  // Set the exercise details in the state
      Alert({ title: 'Success', description: 'Starting exercise: ' + data.name });
    } catch (error) {
      console.error('Error fetching exercise details:', error);
      Alert({ title: 'Error', description: 'Failed to fetch exercise details. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><Heart className="inline-block mr-2" /> Health & Wellness</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Select
            id="exercise"
            value={selectedExercise}
            onChange={(e) => handleExerciseSelect(e.target.value)}
          >
            <option value="">Choose an exercise</option>
            {exercises.map(exercise => (
              <option key={exercise.id} value={exercise.id}>{exercise.name}</option>
            ))}
          </Select>

          {exerciseDetails && (
            <div className="exercise-details">
              <h3>{exerciseDetails.name}</h3>
              <p>{exerciseDetails.description}</p>
              <p>Duration: {exerciseDetails.durationMinutes} minutes</p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default HealthAndWellness;
