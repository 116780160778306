import React from 'react';
import DynamicCarpooling from './DynamicCarpooling';  // Assuming you have separated the components
import PredictiveRideMatching from './PredictiveRideMatching';
import RealTimeTrafficAvoidance from './RealTimeTrafficAvoidance';
import DriverPassengerCompatibility from './DriverPassengerCompatibility';
import ContactlessRideVerification from './ContactlessRideVerification';
import './UniqueFeatures.css';  // Import your CSS

const UniqueFeatures = () => {
  return (
    <div className="features-grid">
      <DynamicCarpooling />
      <PredictiveRideMatching />
      <RealTimeTrafficAvoidance />
      <DriverPassengerCompatibility />
      <ContactlessRideVerification />
    </div>
  );
};

export default UniqueFeatures;
