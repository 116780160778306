import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Input from '../ui/Input';
import { Alert } from '../ui/Alert';
import { Train } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';  // Use Auth context for token

const IntegratedMultiModalTransportation = () => {
  const [startLocation, setStartLocation] = useState('');
  const [endLocation, setEndLocation] = useState('');
  const [routes, setRoutes] = useState([]);  // Ensure routes is initialized as an empty array
  const token = localStorage.getItem('authToken');

  const findRoutes = async () => {
    try {
      const response = await fetch('/api/transportation/multi-modal', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass token in header for authentication
          'Content-Type': 'application/json',
        },
        params: JSON.stringify({ start: startLocation, end: endLocation }),
      });

      const data = await response.json();  // Extract the JSON data
      if (Array.isArray(data)) {
        setRoutes(data);  // Ensure we set only if it's an array
      } else {
        setRoutes([]);  // Handle cases where data is not in the expected format
      }
    } catch (error) {
      console.error('Error finding routes:', error);
      Alert({ title: 'Error', description: 'Failed to find routes. Please try again.' });
    }
  };

  const bookRoute = async (routeId) => {
    try {
      const response = await fetch('/api/transportation/book', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass token in header for booking
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ routeId }),
      });
      Alert({ title: 'Success', description: 'Route booked successfully!' });
    } catch (error) {
      console.error('Error booking route:', error);
      Alert({ title: 'Error', description: 'Failed to book route. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><Train className="inline-block mr-2" /> Integrated Multi-Modal Transportation</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Input
            value={startLocation}
            onChange={(e) => setStartLocation(e.target.value)}
            placeholder="Start Location"
          />
          <Input
            value={endLocation}
            onChange={(e) => setEndLocation(e.target.value)}
            placeholder="End Location"
          />
          <Button onClick={findRoutes}>Find Routes</Button>

          {routes && routes.length > 0 ? (  // Check if routes is an array and has elements
            routes.map((route, index) => (
              <div key={index} className="border p-2 mt-2">
                <h3 className="font-bold">Route {index + 1}</h3>
                <p>Duration: {route.duration} minutes</p>
                <p>Cost: ${route.cost}</p>
                <ul>
                  {route.segments && route.segments.map((segment, segIndex) => (
                    <li key={segIndex}>{segment.type}: {segment.from} to {segment.to}</li>
                  ))}
                </ul>
                <Button onClick={() => bookRoute(route.id)}>Book This Route</Button>
              </div>
            ))
          ) : (
            <p>No routes found. Please try again.</p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default IntegratedMultiModalTransportation;
