import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './LiveTracking.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { FaCar, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import SOSButton from './SOSButton';
import { toast } from 'react-toastify';

const LiveTracking = ({ rideId }) => {
  const [rideDetails, setRideDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tripStatus, setTripStatus] = useState(null); // Track trip status (ON_THE_WAY, ARRIVED, STARTED, COMPLETED)
  const [eta, setEta] = useState(null); // Estimated Time of Arrival
  const stompClientRef = useRef(null);
  const mapRef = useRef(null);

  // Preload notification sound
  const notificationSound = useRef(new Audio('/cab2.wav'));

  const fetchRideDetails = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/${rideId}/track`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching ride details: ${response.statusText}`);
      }

      const data = await response.json();
      setRideDetails(data);
    } catch (error) {
      console.error('Error fetching ride details:', error);
      setError('Unable to fetch ride details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rideId) {
      fetchRideDetails();
    } else {
      setError('Ride ID is missing');
      setLoading(false);
    }

    const token = localStorage.getItem('authToken');
    const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws`);
    const stompClient = new Client({
      webSocketFactory: () => socket,
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      debug: (str) => console.log(str),
      reconnectDelay: 5000,
    });

    stompClient.onConnect = () => {
      console.log('Connected to STOMP server');

      // Listen to driver location updates
      stompClient.subscribe(`/topic/ride/${rideId}/location`, (message) => {
        const data = JSON.parse(message.body);
        console.log('Received location update:', data);
        setRideDetails((prevDetails) => ({ ...prevDetails, ...data }));
      });

      // Listen to trip status updates (arrived, in-progress, completed)
      stompClient.subscribe(`/topic/ride/${rideId}/status`, (message) => {
        console.log('Received status update:', message.body);
        const statusMessage = JSON.parse(message.body);
        console.log('Parsed trip status:', statusMessage);
        setTripStatus(statusMessage);

        // Play notification sound based on status
        if (statusMessage.status === 'ACCEPTED') {
          toast.success('A driver has been assigned.');
        } else if (statusMessage.status === 'IN_PROGRESS') {
          toast.info('Your ride is in progress.');
          notificationSound.current.play();
        } else if (statusMessage.status === 'ARRIVED') {
          toast.info('Driver has arrived at the pickup location.');
          notificationSound.current.play();
        } else if (statusMessage.status === 'COMPLETED') {
          toast.success('Your ride is completed.');
          notificationSound.current.play();
        }

        // Recalculate ETA based on trip status
        if (statusMessage.status === 'IN_PROGRESS') {
          calculateETA(rideDetails.latitude, rideDetails.longitude, rideDetails.dropoffLatitude, rideDetails.dropoffLongitude);
        } else if (statusMessage.status === 'ON_THE_WAY') {
          calculateETA(rideDetails.latitude, rideDetails.longitude, rideDetails.pickupLatitude, rideDetails.pickupLongitude);
        }
      });

      // Listen for driver and vehicle details (when the ride is accepted)
      stompClient.subscribe(`/topic/ride/${rideId}/details`, (message) => {
        const detailsData = JSON.parse(message.body);
        console.log('Received ride details update:', detailsData);
        setRideDetails((prevDetails) => ({ ...prevDetails, ...detailsData }));
      });
    };

    stompClient.onStompError = (frame) => {
      console.error('STOMP Error:', frame.headers['message'], frame.body);
      setError('WebSocket connection failed');
    };

    stompClient.activate();
    stompClientRef.current = stompClient;

    return () => {
      if (stompClientRef.current) {
        stompClientRef.current.deactivate();
      }
    };
  }, [rideId]);

  const MapUpdater = ({ rideDetails }) => {
    const map = useMap();

    useEffect(() => {
      if (
        rideDetails?.pickupLatitude &&
        rideDetails?.pickupLongitude &&
        rideDetails?.dropoffLatitude &&
        rideDetails?.dropoffLongitude
      ) {
        const bounds = L.latLngBounds([
          [rideDetails.pickupLatitude, rideDetails.pickupLongitude],
          [rideDetails.dropoffLatitude, rideDetails.dropoffLongitude],
        ]);
        map.fitBounds(bounds);
      } else if (rideDetails?.pickupLatitude && rideDetails?.pickupLongitude) {
        map.setView([rideDetails.pickupLatitude, rideDetails.pickupLongitude], 15);
      }
    }, [map, rideDetails]);

    return null;
  };

  // Function to calculate the distance between two geographic coordinates using the Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    if (!lat1 || !lon1 || !lat2 || !lon2) {
      return 0;
    }

    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Function to calculate the ETA based on remaining distance and speed
  const calculateETA = (lat1, lon1, lat2, lon2) => {
    const distance = calculateDistance(lat1, lon1, lat2, lon2);
    const AVERAGE_SPEED_KMH = 40; // Average driving speed in km/h
    const timeInHours = distance / AVERAGE_SPEED_KMH;
    const estimatedArrival = new Date(Date.now() + timeInHours * 3600 * 1000);
    setEta(estimatedArrival.toLocaleTimeString()); // Set the estimated arrival time
  };

  if (loading) {
    return <div className="loading">Loading ride details...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const pickupIcon = L.icon({
    iconUrl: '/icons/pickup.png',
    iconSize: [32, 32],
  });

  const dropoffIcon = L.icon({
    iconUrl: '/icons/dropoff.png',
    iconSize: [32, 32],
  });

  const driverIcon = L.icon({
    iconUrl: '/icons/driver.png',
    iconSize: [32, 32],
  });

  return (
    <div className="live-tracking-container">
      <h2>Track Your Ride</h2>
      <div className="tracking-content">
        {/* Map Section */}
        <MapContainer
          center={[
            rideDetails.latitude || rideDetails.pickupLatitude,
            rideDetails.longitude || rideDetails.pickupLongitude,
          ]}
          zoom={15}
          className="map-container"
            style={{ height: '100%' }} // Add this to ensure it takes up the full height
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />

          <Marker position={[rideDetails.pickupLatitude, rideDetails.pickupLongitude]} icon={pickupIcon}>
            <Popup>
              <b>Pickup Location</b>
              <br />
              Latitude: {rideDetails.pickupLatitude}
              <br />
              Longitude: {rideDetails.pickupLongitude}
            </Popup>
          </Marker>

          <Marker position={[rideDetails.dropoffLatitude, rideDetails.dropoffLongitude]} icon={dropoffIcon}>
            <Popup>
              <b>Dropoff Location</b>
              <br />
              Latitude: {rideDetails.dropoffLatitude}
              <br />
              Longitude: {rideDetails.dropoffLongitude}
            </Popup>
          </Marker>

          {rideDetails.latitude && rideDetails.longitude && (
            <Marker position={[rideDetails.latitude, rideDetails.longitude]} icon={driverIcon}>
              <Popup>
                <b>Driver's Current Location</b>
              </Popup>
            </Marker>
          )}

          <MapUpdater rideDetails={rideDetails} />
        </MapContainer>

        {/* Ride Details Section */}
        <div className="ride-details-panel">
          <SOSButton userId={rideDetails.driverId} rideId={rideId} />
          <h3>
            <FaCar /> Driver: {rideDetails.driverName}
          </h3>
          <p>
            <FaMapMarkerAlt /> Vehicle: {rideDetails.model}
          </p>
          <p>
            <FaClock /> Estimated Arrival Time: {eta || 'Calculating...'}
          </p>
          <div className="progress-bar">
            <span>Distance to Destination: {calculateDistance(
              rideDetails.latitude || rideDetails.pickupLatitude,
              rideDetails.longitude || rideDetails.pickupLongitude,
              rideDetails.dropoffLatitude,
              rideDetails.dropoffLongitude
            ).toFixed(2)} km
            </span>
          </div>
          <div className="trip-status">
            <strong>Trip Status: {tripStatus?.status || rideDetails.status}</strong>
            {tripStatus?.message && <p>Message: {tripStatus.message}</p>}
            {tripStatus?.details && (
              <>
                <p><strong>Driver:</strong> {tripStatus.details.driverName}</p>
                <p><strong>Vehicle:</strong> {tripStatus.details.vehicleDetails}</p>
                <p><strong>Pickup Location:</strong> {tripStatus.details.pickupLocation}</p>
                <p><strong>Dropoff Location:</strong> {tripStatus.details.dropoffLocation}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveTracking;
