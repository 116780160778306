import React, { useState } from 'react';
import { Alert } from '../ui/Alert';
import Switch from '../ui/Switch';  // Adjust the relative path

const DynamicCarpooling = () => {
  const [isCarpoolingEnabled, setIsCarpoolingEnabled] = useState(false);
  const [potentialMatches, setPotentialMatches] = useState([]);
  const [error, setError] = useState(null);  // State to track errors
  const token = localStorage.getItem('authToken');  // Fetch token from localStorage

  const toggleCarpooling = async () => {
    setIsCarpoolingEnabled(!isCarpoolingEnabled);
    if (!isCarpoolingEnabled) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/carpooling/potential-matches`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Pass token for authentication
            'Content-Type': 'application/json'
          },
        });

        if (response.status === 204) {
          // No content, handle accordingly
          setPotentialMatches([]);  // No matches found, so set to empty array
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch potential matches');
        }

        const data = await response.json();
        setPotentialMatches(data);
      } catch (error) {
        console.error('Error fetching potential matches:', error);
        setError('Error fetching potential matches. Please try again.');
      }
    } else {
      setPotentialMatches([]);  // Clear matches when carpooling is disabled
    }
  };

  return (
    <div className="card">
      <div className="card-header">Dynamic Carpooling</div>
      <div className="card-content">
        {error && <Alert title="Error" description={error} />}
        <Switch checked={isCarpoolingEnabled} onCheckedChange={toggleCarpooling}>
          Enable Dynamic Carpooling
        </Switch>
        {isCarpoolingEnabled && potentialMatches.length > 0 && (
          <ul>
            {potentialMatches.map((match, index) => (
              <li key={index}>{match.name} - {match.destination}</li>
            ))}
          </ul>
        )}
        {isCarpoolingEnabled && potentialMatches.length === 0 && <p>No matches found</p>}
      </div>
    </div>
  );
};

export default DynamicCarpooling;
