// src/components/LanguageSwitcher.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css'; // Move styles to a CSS file

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language).then(() => {
      setActiveLanguage(language); // Track the active language
    });
  };

  return (
    <div className="language-switcher">
      <button
        className={activeLanguage === 'en' ? 'active' : ''}
        onClick={() => changeLanguage('en')}
      >
        English
      </button>
      <button
        className={activeLanguage === 'es' ? 'active' : ''}
        onClick={() => changeLanguage('es')}
      >
        Español
      </button>
      <button
        className={activeLanguage === 'hi' ? 'active' : ''}
        onClick={() => changeLanguage('hi')}
      >
        हिंदी
      </button>
    </div>
  );
};

export default LanguageSwitcher;
