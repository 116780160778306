import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Label from '../ui/Label';
import Select from '../ui/Select';
import { Alert } from '../ui/Alert';
import { MapPin } from 'lucide-react';
import { useAuth } from '../../context/AuthContext'; // Assuming you're using an Auth context to manage user auth

const LocalTourGuideOption = () => {
  const [tourGuides, setTourGuides] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState('');
  const [tourDetails, setTourDetails] = useState(null);

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const fetchTourGuides = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/tours/guides`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Add token in Authorization header
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tour guides');
        }

        const data = await response.json();
        setTourGuides(data);
      } catch (error) {
        console.error('Error fetching tour guides:', error);
        Alert({ title: 'Error', description: 'Failed to fetch tour guides. Please try again.' });
      }
    };

    if (token) {
      fetchTourGuides(); // Fetch data when token is available
    }
  }, [token]);

  const handleGuideSelect = async (guideId) => {
    setSelectedGuide(guideId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/tours/guide-details/${guideId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Add token in Authorization header
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch guide details');
      }

      const data = await response.json();
      setTourDetails(data);
    } catch (error) {
      console.error('Error fetching guide details:', error);
      Alert({ title: 'Error', description: 'Failed to fetch guide details. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <MapPin className="inline-block mr-2" /> Local Tour Guide Option
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Label htmlFor="guide">Select Local Tour Guide</Label>
          <Select
            id="guide"
            value={selectedGuide}
            onChange={(e) => handleGuideSelect(e.target.value)}
          >
            <option value="">Select a guide</option>
            {tourGuides.map((guide) => (
              <option key={guide.id} value={guide.id}>
                {guide.name} - {guide.speciality}
              </option>
            ))}
          </Select>
          {tourDetails && (
            <div>
              <h3 className="font-bold">{tourDetails.name}</h3>
              <p>Speciality: {tourDetails.speciality}</p>
              <p>Experience: {tourDetails.experience} years</p>
              <p>Rating: {tourDetails.rating}/5</p>
              <p>Price: ${tourDetails.pricePerHour}/hour</p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default LocalTourGuideOption;
