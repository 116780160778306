import React, { useEffect, useState } from 'react';

const PredictiveRideMatching = () => {
  const [predictedRides, setPredictedRides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('authToken');  // Get the auth token

  useEffect(() => {
    const fetchPredictedRides = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/predicted`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Pass token for authentication
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch predicted rides: ${response.statusText}`);
        }

        const data = await response.json();
        setPredictedRides(data);
      } catch (error) {
        setError('Error fetching predicted rides. Please try again later.');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPredictedRides();
  }, [token]);

  return (
    <div className="card">
      <div className="card-header">Predictive Ride Matching</div>
      <div className="card-content">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : predictedRides.length > 0 ? (
          predictedRides.map((ride, index) => (
            <div key={index} className="border p-2 rounded">
              <p><strong>Time:</strong> {ride.predictedTime}</p>
              <p><strong>Destination:</strong> {ride.destination}</p>
            </div>
          ))
        ) : (
          <p>No predicted rides available for today.</p>
        )}
      </div>
    </div>
  );
};

export default PredictiveRideMatching;
