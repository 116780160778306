import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Input from '../ui/Input';
import Checkbox from '../ui/Checkbox';
import { Alert } from '../ui/Alert';
import { Calendar } from 'lucide-react';

const ScheduledRides = () => {
  const [schedules, setSchedules] = useState([]);
  const [newSchedule, setNewSchedule] = useState({
    pickup: '',
    dropoff: '',
    time: '',
    days: [],
    isRecurring: false,
  });

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/schedules`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
      Alert({ title: 'Error', description: 'Failed to fetch schedules. Please try again.' });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSchedule(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/schedule`, newSchedule, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Alert({ title: 'Success', description: 'Ride scheduled successfully!' });
      fetchSchedules();
      setNewSchedule({ pickup: '', dropoff: '', time: '', days: [], isRecurring: false });
    } catch (error) {
      console.error('Error scheduling ride:', error);
      Alert({ title: 'Error', description: 'Failed to schedule ride. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><Calendar className="inline-block mr-2" /> Scheduled Rides</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            name="pickup"
            placeholder="Pickup Location"
            value={newSchedule.pickup}
            onChange={handleInputChange}
            required
          />
          <Input
            name="dropoff"
            placeholder="Dropoff Location"
            value={newSchedule.dropoff}
            onChange={handleInputChange}
            required
          />
          <Input
            name="time"
            type="time"
            value={newSchedule.time}
            onChange={handleInputChange}
            required
          />
          <Checkbox
            name="isRecurring"
            checked={newSchedule.isRecurring}
            onCheckedChange={(checked) => setNewSchedule(prev => ({ ...prev, isRecurring: checked }))}
          >
            Recurring Ride
          </Checkbox>
          <Button type="submit">Schedule Ride</Button>
        </form>
        <div className="mt-4">
          <h3 className="font-bold">Your Scheduled Rides</h3>
          {schedules.map((schedule, index) => (
            <div key={index} className="mt-2 p-2 border rounded">
              <p>{schedule.pickup} to {schedule.dropoff}</p>
              <p>Time: {schedule.time}</p>
              {schedule.isRecurring && (
                <p>Days: {schedule.days.join(', ')}</p>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ScheduledRides;
