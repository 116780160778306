import React from 'react';
import './Button.css'; // Ensure you have a Button.css file in the same directory

export const Button = ({ children, onClick, disabled }) => (
  <button className="custom-button" onClick={onClick} disabled={disabled}>
    {children}
  </button>
);

export default Button;
