import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '../ui/Button';
import  Input  from '../ui/Input';
const ContactlessRideVerification = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const generateVerificationCode = async () => {
    try {
      const response = await axios.post('/api/rides/generate-verification-code');
      setVerificationCode(response.data.code);
    } catch (error) {
      console.error('Error generating verification code:', error);
    }
  };

  const verifyRide = async () => {
    try {
      const response = await axios.post('/api/rides/verify', { code: verificationCode });
      setIsVerified(response.data.verified);
    } catch (error) {
      console.error('Error verifying ride:', error);
    }
  };

  return (
    <div className="card">
      <div className="card-header">Contactless Ride Verification</div>
      <div className="card-content">
        <Button onClick={generateVerificationCode}>Generate Verification Code</Button>
        {verificationCode && (
          <div>
            <p>Verification Code: {verificationCode}</p>
            <Input
              type="text"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <Button onClick={verifyRide}>Verify Ride</Button>
          </div>
        )}
        {isVerified && <p className="text-green-500">Ride Verified!</p>}
      </div>
    </div>
  );
};

export default ContactlessRideVerification;
