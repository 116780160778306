// src/ui/Select.js
import React from 'react';
import './Select.css';  // If you have specific styles for Select

const Select = ({ children, onChange, multiple, id }) => (
  <select className="select" id={id} multiple={multiple} onChange={onChange}>
    {children}
  </select>
);

export default Select;
