import axios from 'axios';
import Register from '../components/Authentication/Register';

// Replace this with your backend API URL
const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/rides`; // Correct backend URL

// Function to get the authentication token from localStorage (or sessionStorage)
const getAuthToken = () => {
  const token = localStorage.getItem('authToken');  // Retrieve the token from localStorage
  console.log('Auth Token:', token);  // Log token to verify if it's being retrieved
  return token;
};



// services/api.js

export const bookRide = async (bookingData) => {
  const token = localStorage.getItem('authToken');

  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/book`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(bookingData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to book the ride');
  }

  return await response.json();
};


// Fetch live ride data for real-time tracking
export const getLiveRides = () => {
  return axios.get(`${API_URL}/rides/live`);
};


// Function to get booking history for a user
export const getBookingHistory = (userId) => {
  const token = getAuthToken();
  return axios.get(`${API_URL}/UserRides`, {
    headers: {
      Authorization: `Bearer ${token}` // Include the Authorization header
    }
  });
};

// Geocode an address (convert address to coordinates)
export const geocodeAddress = async (address) => {
  const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`;

  const response = await fetch(url);
  const data = await response.json();

  if (data && data.length > 0) {
    const { lat, lon } = data[0]; // Extract latitude and longitude
    return { latitude: parseFloat(lat), longitude: parseFloat(lon) };
  } else {
    throw new Error('Address not found');
  }
};

// Calculate distance and duration using OSRM
export const calculateDistanceAndDuration = async (pickupCoordinates, dropCoordinates) => {
  const url = `http://router.project-osrm.org/route/v1/driving/${pickupCoordinates.longitude},${pickupCoordinates.latitude};${dropCoordinates.longitude},${dropCoordinates.latitude}?overview=false`;

  const response = await fetch(url);
  const data = await response.json();

  if (data.code === 'Ok') {
    const route = data.routes[0];
    const distance = route.distance / 1000; // Distance in kilometers
    const duration = route.duration / 60; // Duration in minutes
    return { distance, duration };
  } else {
    throw new Error('Error calculating distance and duration');
  }
};


// Default export, exporting both functions
export default {
  bookRide,
  getBookingHistory,getLiveRides
};
