import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import './PreferredDrivers.css';

const PreferredDrivers = () => {
  const [preferredDrivers, setPreferredDrivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchPreferredDrivers();
  }, []);

  // Fetch preferred drivers for the current user
  const fetchPreferredDrivers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/passenger/preferred-drivers`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch preferred drivers');
      }
      const data = await response.json();
      setPreferredDrivers(data); // Ensure data matches the DTO structure
    } catch (error) {
      console.error('Error fetching preferred drivers:', error);
      toast.error('Error fetching preferred drivers');
    }
  };

  // Search drivers by term
  const searchDrivers = async () => {
    if (searchTerm.trim().length < 3) {
      toast.info('Enter at least 3 characters to search.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/drivers/search?term=${searchTerm}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to search drivers');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching drivers:', error);
      toast.error('Error searching drivers');
    }
  };

  const addPreferredDriver = async (driverId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/passenger/add-preferred-driver`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ driverId })
      });
      if (!response.ok) {
        throw new Error('Failed to add preferred driver');
      }
      toast.success('Driver added to preferred list');
      fetchPreferredDrivers(); // Refresh the list after adding
      setSearchResults([]); // Clear search results after adding
    } catch (error) {
      console.error('Error adding preferred driver:', error);
      toast.error('Error adding preferred driver');
    }
  };

  const removePreferredDriver = async (driverId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/passenger/remove-preferred-driver`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ driverId })
      });
      if (!response.ok) {
        throw new Error('Failed to remove preferred driver');
      }
      toast.success('Driver removed from preferred list');
      fetchPreferredDrivers(); // Refresh the list after removing
    } catch (error) {
      console.error('Error removing preferred driver:', error);
      toast.error('Error removing preferred driver');
    }
  };

  return (
    <div className="preferred-drivers-container">
      <h2>Your Preferred Drivers</h2>
      <div className="search-drivers">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search drivers..."
        />
        <button onClick={searchDrivers}>Search</button>
      </div>
      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Search Results</h3>
          <ul>
            {searchResults.map(driver => (
              <li key={driver.id}>
                {driver.name}
                <button onClick={() => addPreferredDriver(driver.id)}>Add to Preferred</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="preferred-drivers-list">
        <h3>Your Preferred Drivers</h3>
        {preferredDrivers.length > 0 ? (
          <ul>
            {preferredDrivers.map(preferredDriver => (
              <li key={preferredDriver.id}>
                {/* Ensure this matches your DTO structure */}
                {preferredDriver.driverId} {/* Change as per your DTO structure */}
                <button onClick={() => removePreferredDriver(preferredDriver.driverId)}>Remove</button>
              </li>
            ))}
          </ul>
        ) : (
          <p>You haven't added any preferred drivers yet.</p>
        )}
      </div>
    </div>
  );
};

export default PreferredDrivers;
