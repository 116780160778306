import React, { useState, useEffect } from 'react';
import './RideSharing.css';

const RideSharing = ({ bookingDetails, onShareRide, onCancelShare }) => {
  const [isSharing, setIsSharing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [potentialMatches, setPotentialMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);

  useEffect(() => {
    if (isSharing) {
      fetchPotentialMatches();
    }
  }, [isSharing]);

const fetchPotentialMatches = async () => {
  setLoading(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/carpooling/potential-matches`, { // Update URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify(bookingDetails) // Ensure bookingDetails matches backend expectations
    });

    if (!response.ok) {
      throw new Error('Failed to fetch potential matches');
    }

    const matches = await response.json();
    setPotentialMatches(matches);
  } catch (error) {
    console.error('Error fetching potential matches:', error);
    alert('Failed to find potential matches. Please try again later.');
  } finally {
    setLoading(false);
  }
};


  const handleShareToggle = () => {
    if (!isSharing) {
      onShareRide(); // Call when ride sharing starts
    } else {
      onCancelShare(); // Call when ride sharing is canceled
      setPotentialMatches([]); // Clear potential matches
      setSelectedMatch(null);  // Reset selected match
    }
    setIsSharing(!isSharing); // Toggle sharing state
  };

  const handleMatchSelect = (match) => {
    setSelectedMatch(match); // Set selected match
  };

  const confirmSharedRide = async () => {
    if (!selectedMatch) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/confirm-shared`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          bookingId: bookingDetails.id, // Pass current booking ID
          matchId: selectedMatch.id     // Pass selected match ID
        })
      });

      if (!response.ok) {
        throw new Error('Failed to confirm shared ride');
      }

      alert('Shared ride confirmed!');
    } catch (error) {
      console.error('Error confirming shared ride:', error);
      alert('Failed to confirm shared ride. Please try again.');
    }
  };

  return (
    <div className="ride-sharing-container">
      <h2>Ride Sharing</h2>
      <div className="sharing-toggle">
        <label>
          <input
            type="checkbox"
            checked={isSharing}
            onChange={handleShareToggle}
          />
          I'm willing to share my ride
        </label>
      </div>
      {isSharing && (
        <div className="potential-matches">
          <h3>Potential Matches</h3>
          {loading ? (
            <p>Loading matches...</p>
          ) : potentialMatches.length > 0 ? (
            <ul>
              {potentialMatches.map((match) => (
                <li key={match.id} onClick={() => handleMatchSelect(match)}>
                  <div className={`match-item ${selectedMatch && selectedMatch.id === match.id ? 'selected' : ''}`}>
                    <p>From: {match.pickupLocation}</p>
                    <p>To: {match.dropLocation}</p>
                    <p>Estimated savings: ${match.estimatedSavings.toFixed(2)}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No potential matches found at the moment. We'll keep searching!</p>
          )}
        </div>
      )}
      {selectedMatch && (
        <button className="confirm-shared-ride" onClick={confirmSharedRide}>
          Confirm Shared Ride
        </button>
      )}
    </div>
  );
};

export default RideSharing;
