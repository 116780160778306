import React from 'react';
import { useLocation } from 'react-router-dom';
import './BookingConfirmation.css';  // Import the CSS file

const BookingConfirmation = () => {
  const location = useLocation();
  const bookingDetails = location.state?.bookingDetails;

  return (
    <div className="booking-confirmation-container">
      <h1>Booking Confirmation</h1>
      <div className="confirmation-details">
        <p><strong>Ride ID:</strong> {bookingDetails?.rideId || 'N/A'}</p>
        <p><strong>Pickup Location:</strong> {bookingDetails?.pickupLocation || 'N/A'}</p>
        <p><strong>Dropoff Location:</strong> {bookingDetails?.dropoffLocation || 'N/A'}</p>
        <p><strong>Driver Name:</strong> {bookingDetails?.driverName || 'Driver not assigned'}</p>
        <p><strong>Vehicle Type:</strong> {bookingDetails?.vehicleDetails || 'Not available'}</p>
        <p><strong>Estimated Fare:</strong> {bookingDetails?.fare ? `₹${bookingDetails.fare.toFixed(2)}` : 'Fare not available'}</p>
        <p><strong>Booking Time:</strong> {bookingDetails?.bookingTime ? new Date(bookingDetails.bookingTime).toLocaleString() : 'Not available'}</p>
        <p><strong>Status:</strong> {bookingDetails?.status || 'Pending'}</p>
      </div>
    </div>
  );
};

export default BookingConfirmation;
