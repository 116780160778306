import React, { useState } from 'react';
import { toast } from 'react-toastify'; // Assuming you have react-toastify installed

function DriverActions({ rideId }) {
  const [rideState, setRideState] = useState('waiting'); // 'waiting', 'inProgress', 'completed'

  const handleArrived = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/${rideId}/arrived`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (response.ok) {
        setRideState('inProgress');
        toast.success('Driver has arrived at pickup location');
      } else {
        toast.error('Failed to mark arrival');
      }
    } catch (error) {
      toast.error('Error in driver arrived action');
    }
  };

  const handleStartTrip = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/${rideId}/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (response.ok) {
        setRideState('completed');
        toast.success('Trip has started');
      } else {
        toast.error('Failed to start trip');
      }
    } catch (error) {
      toast.error('Error in starting the trip');
    }
  };

  const handleCompleteTrip = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/${rideId}/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (response.ok) {
        setRideState('waiting');
        toast.success('Trip completed successfully');
      } else {
        toast.error('Failed to complete trip');
      }
    } catch (error) {
      toast.error('Error in completing the trip');
    }
  };

  return (
    <div>
      <button onClick={handleArrived} disabled={rideState !== 'waiting'}>Driver Arrived</button>
      <button onClick={handleStartTrip} disabled={rideState !== 'inProgress'}>Start Trip</button>
      <button onClick={handleCompleteTrip} disabled={rideState !== 'completed'}>Complete Trip</button>
    </div>
  );
}

export default DriverActions;
