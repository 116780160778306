import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Input from '../ui/Input';
import { Alert } from '../ui/Alert';
import { MapPin } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';  // Assuming you have a token management context

const CommunityPointsOfInterest = () => {
  const [pois, setPois] = useState([]);
  const [newPoi, setNewPoi] = useState({ name: '', description: '', location: '' });
  const token = localStorage.getItem('authToken');  // Get auth token from localStorage or context

  // Fetch points of interest function
  const fetchPois = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/community/pois`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass token for authentication
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch points of interest');
      }

      const data = await response.json();
      setPois(data);
    } catch (error) {
      console.error('Error fetching points of interest:', error);
      Alert({ title: 'Error', description: 'Failed to fetch points of interest. Please try again.' });
    }
  };

  // Call fetchPois inside useEffect on component mount
  useEffect(() => {
    fetchPois();
  }, []);

  // Handle input change for new point of interest
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPoi((prev) => ({
      ...prev,
      [name]: value,  // Update the field corresponding to the name
    }));
  };

  // Handle form submission for adding a new point of interest
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPoi.name || !newPoi.description || !newPoi.location) {
      Alert({ title: 'Error', description: 'All fields are required' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/community/pois`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass token for authentication
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPoi),  // Send new point of interest in request body
      });

      if (!response.ok) {
        throw new Error('Failed to add point of interest');
      }

      Alert({ title: 'Success', description: 'Point of interest added successfully!' });
      setNewPoi({ name: '', description: '', location: '' });  // Clear the form
      fetchPois();  // Refresh the list after adding a new POI
    } catch (error) {
      console.error('Error adding point of interest:', error);
      Alert({ title: 'Error', description: 'Failed to add point of interest. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><MapPin className="inline-block mr-2" /> Community Points of Interest</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            name="name"
            placeholder="Name of Point of Interest"
            value={newPoi.name}
            onChange={handleInputChange}  // Controlled component handling input
            required
          />
          <Input
            name="description"
            placeholder="Description"
            value={newPoi.description}
            onChange={handleInputChange}  // Controlled component handling input
            required
          />
          <Input
            name="location"
            placeholder="Location"
            value={newPoi.location}
            onChange={handleInputChange}  // Controlled component handling input
            required
          />
          <Button type="submit">Add Point of Interest</Button>
        </form>
        <div className="mt-4">
          <h3 className="font-bold">Community-Shared Points of Interest</h3>
          {pois.length > 0 ? (
            pois.map((poi, index) => (
              <div key={index} className="mt-2 p-2 border rounded">
                <h4 className="font-bold">{poi.name}</h4>
                <p>{poi.description}</p>
                <p>Location: {poi.location}</p>
              </div>
            ))
          ) : (
            <p>No points of interest available</p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CommunityPointsOfInterest;
