import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ContrastMode.css'; // Ensure the path is correct

const ContrastMode = () => {
  const { t } = useTranslation();

  // Initialize the state directly from localStorage to avoid unnecessary re-renders
  const [highContrast, setHighContrast] = useState(() => {
    const savedMode = localStorage.getItem('highContrastMode');
    return savedMode !== null ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    // Apply or remove the 'high-contrast' class based on the state
    document.body.classList.toggle('high-contrast', highContrast);
    // Store the state in localStorage whenever it changes
    const currentMode = localStorage.getItem('highContrastMode');
    if (currentMode !== JSON.stringify(highContrast)) {
      localStorage.setItem('highContrastMode', JSON.stringify(highContrast));
    }
  }, [highContrast]);

  const toggleContrast = () => {
    setHighContrast(prev => !prev);
  };

  return (
    <div className="contrast-mode">
      <label className="switch">
        <input
          type="checkbox"
          checked={highContrast}
          onChange={toggleContrast}
          aria-label={t('Toggle High Contrast Mode')}
          aria-checked={highContrast}
        />
        <span className="slider" aria-hidden="true"></span>
      </label>
      <span aria-live="polite">{t('High Contrast Mode')}</span>
    </div>
  );
};

export default ContrastMode;
