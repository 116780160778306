import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { bookRide, getBookingHistory, geocodeAddress, calculateDistanceAndDuration } from '../../services/api';
import { useAuth } from '../../context/AuthContext'; // Use the auth context to get the user's role
import './Dashboard.css';
import Profile from '../Profile/Profile';
import RideUpdates from '../Driver/RideUpdates';  // Import RideUpdates for real-time updates for users

const Dashboard = () => {
  const navigate = useNavigate();
  const { userRole } = useAuth(); // Get the user's role from the auth context

  const [recentRides, setRecentRides] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [bookingForm, setBookingForm] = useState({
    pickupLocation: '',
    dropLocation: '',
    vehicleType: 'Sedan',
  });

  const [earningsData, setEarningsData] = useState({
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Monthly Earnings",
        data: [],
        fill: false,
        borderColor: '#742774'
      }
    ]
  });

  // Custom flag to track if the dashboard data was already fetched
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const userId = localStorage.getItem('userId');

        if (!dataFetched) {
          if (userRole === 'USER') {
            // Fetch booking history only if not previously fetched
            const rideResponse = await getBookingHistory(userId);
            setRecentRides(rideResponse.data);
          } else if (userRole === 'DRIVER') {
            // Load driver documents and earnings only if the role is DRIVER
            await loadDriverDocuments(userId);
            await loadEarningsData();
          }
          setDataFetched(true); // Mark data as fetched to avoid infinite loop
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userRole && !dataFetched) {
      loadDashboardData(); // Ensure it only runs once when userRole is set and data isn't already fetched
    }
  }, [userRole, dataFetched]); // Only run when userRole or dataFetched changes

  const loadDriverDocuments = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/driver_documents/${userId}`);
      const data = await response.json();
      setDocuments(data); // Set the documents data
    } catch (error) {
      console.error('Error fetching driver documents:', error);
    }
  };

  const loadEarningsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/earnings/${localStorage.getItem('userId')}`);
      const data = await response.json();
      setEarningsData({
        labels: data.map(item => item.month),
        datasets: [{
          label: "Monthly Earnings",
          data: data.map(item => item.earnings),
          borderColor: '#742774',
          fill: false
        }]
      });
    } catch (error) {
      console.error('Error loading earnings data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingForm(prev => ({ ...prev, [name]: value }));
  };

  const handleBookRide = async (e) => {
    e.preventDefault();
    try {
      const pickupCoordinates = await geocodeAddress(bookingForm.pickupLocation);
      const dropCoordinates = await geocodeAddress(bookingForm.dropLocation);
      const { distance, duration } = await calculateDistanceAndDuration(pickupCoordinates, dropCoordinates);
      const bookingData = {
        pickupLocation: bookingForm.pickupLocation,
        dropLocation: bookingForm.dropLocation,
        vehicleType: bookingForm.vehicleType,
        distanceInKm: parseFloat(distance),
        durationInMinutes: parseFloat(duration),
        pickupLatitude: pickupCoordinates.latitude,
        pickupLongitude: pickupCoordinates.longitude,
        dropoffLatitude: dropCoordinates.latitude,
        dropoffLongitude: dropCoordinates.longitude,
      };
      const data = await bookRide(bookingData);
      alert('Ride booked successfully! Ride ID: ' + data.rideId);
      navigate('/booking-confirmation', { state: { bookingDetails: data } });
    } catch (error) {
      console.error('Error booking ride:', error);
      alert('Failed to book ride: ' + error.message);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('driver_id', localStorage.getItem('userId'));
    formData.append('document_type', documentType);
    formData.append('expiry_date', expiryDate);

    try {
      const response = await fetch('/upload_document', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Document uploaded successfully!');
        loadDriverDocuments(localStorage.getItem('userId'));
      } else {
        alert('Failed to upload document. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('An error occurred while uploading the document.');
    }
  };

  if (loading) {
    return <div>Loading dashboard...</div>;
  }

  return (
    <div className="dashboard-container">
      <h1>Welcome, {userRole === 'USER' ? 'Customer' : 'Driver'}!</h1>

      <div className="dashboard-grid">
        {/* Show earnings and documents for drivers */}
        {userRole === 'DRIVER' && (
          <>
            <div className="earnings-chart card">
              <h2>Earnings Overview</h2>
              <Line data={earningsData} />
            </div>

            <div className="driver-documents card">
              <h2>Driver Documents</h2>
              <input type="file" onChange={handleFileChange} />
              <input type="text" placeholder="Document Type" value={documentType} onChange={(e) => setDocumentType(e.target.value)} />
              <input type="date" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
              <button onClick={handleUpload}>Upload Document</button>
              <ul>
                {documents.map((doc, index) => (
                  <li key={index}>
                    <span>{doc.document_type} - Expires: {doc.expiry_date}</span>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        {/* Show recent rides and booking form for users */}
        {userRole === 'USER' && (
          <>
            <div className="quick-book card">
              <h2>Quick Book</h2>
              <form onSubmit={handleBookRide}>
                <input
                  type="text"
                  name="pickupLocation"
                  value={bookingForm.pickupLocation}
                  onChange={handleInputChange}
                  placeholder="Pickup Location"
                  required
                />
                <input
                  type="text"
                  name="dropLocation"
                  value={bookingForm.dropLocation}
                  onChange={handleInputChange}
                  placeholder="Drop Location"
                  required
                />
                <select
                  name="vehicleType"
                  value={bookingForm.vehicleType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Sedan">Sedan</option>
                  <option value="SUV">SUV</option>
                  <option value="Luxury">Luxury</option>
                </select>
                <button type="submit" className="book-ride-btn">Book Now</button>
              </form>
            </div>

            <div className="ride-history card">
              <h2>Recent Ride</h2>
              {recentRides.length > 0 ? (
                <ul className="ride-list">
                  {recentRides.slice().reverse().slice(0, 1).map((ride, index) => (
                    <li key={ride.id} className="ride-item">
                      <div className="ride-info">
                        <div className="ride-id"><strong>Ride ID:</strong> {ride.rideId}</div>
                        <div className="ride-location">
                          <strong>Pickup:</strong> {ride.pickupLocation}
                        </div>
                        <div className="ride-location">
                          <strong>Dropoff:</strong> {ride.dropoffLocation}
                        </div>
                      </div>
                      <div className="ride-status">
                        <strong>Status:</strong> {ride.status}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No recent rides found.</p>
              )}
            </div>

          </>
        )}

        {/* Common stats section */}
        <div className="stats card">
          <h2>Your Stats</h2>
          <div className="stat-item">
            <span>Total Rides</span>
            <span>{recentRides.length}</span>
          </div>
          <div className="stat-item">
            <span>This Month</span>
            <span>
              {recentRides.filter((ride) => {
                const rideDate = new Date(ride.bookingTime); // Use bookingTime instead of createdAt
                const currentMonth = new Date().getMonth();
                const currentYear = new Date().getFullYear();
                return rideDate.getMonth() === currentMonth && rideDate.getFullYear() === currentYear;
              }).length}
            </span>
          </div>

        </div>

        <div className="quick-actions card">
          <h2>Quick Actions</h2>
          <button onClick={() => navigate('/profile')}>Edit Profile</button>
          <button onClick={() => navigate('/support')}>Contact Support</button>
          <button onClick={() => navigate('/promotions')}>View Promotions</button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
