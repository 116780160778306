import React, { useState, useEffect } from 'react';
import DriverLocationUpdate from '../Booking/DriverLocationUpdate';
import DriverNotifications from '../Driver/DriverNotifications';
import DriverActions from '../Driver/DriverActions';
import DriverMap from '../Driver/DriverMap'; // Import the map component
import './DriverManagement.css';
import { toast } from 'react-toastify';

function DriverManagement() {
  const [isOnline, setIsOnline] = useState(false);
  const [driverId, setDriverId] = useState(null);
  const [rideId, setRideId] = useState(null);
  const [driverLocation, setDriverLocation] = useState(null); // Driver's location
  const [pickupLocation, setPickupLocation] = useState(null); // Pickup location from ride request
  const [rideInProgress, setRideInProgress] = useState(false); // Track if the ride is in progress

  useEffect(() => {
    const storedDriverId = localStorage.getItem('driverId');
    if (storedDriverId) {
      setDriverId(storedDriverId);
    }
  }, []);

  const toggleOnlineStatus = () => {
    // Prevent going offline if a ride is in progress
    if (!rideInProgress) {
      setIsOnline(!isOnline);
    } else {
      toast.warning("You cannot go offline during an active ride.");
    }
  };

  const handleRideAccepted = (rideRequest) => {
    console.log('Ride request accepted:', rideRequest);

    // Check if ride request contains valid latitude and longitude
    if (rideRequest.pickupLatitude && rideRequest.pickupLongitude) {
      setRideId(rideRequest.rideId);
      setPickupLocation({
        lat: rideRequest.pickupLatitude,
        lon: rideRequest.pickupLongitude,
      });
      setRideInProgress(true); // Mark ride as in progress
      console.log('Valid ride request received:', rideRequest);
    } else {
      console.error('Invalid ride request data: Missing latitude or longitude', rideRequest);
      setPickupLocation(null);
      toast.error('Invalid ride request: Missing pickup coordinates.');
    }
  };

  const handleRideCompletion = () => {
    setRideInProgress(false); // Mark ride as completed
    setRideId(null);
    setPickupLocation(null);
    toast.success("Ride completed. You can now go offline.");
  };

  useEffect(() => {
    console.log('Current rideId:', rideId);
    console.log('Current pickupLocation:', pickupLocation ? `${pickupLocation.lat}, ${pickupLocation.lon}` : 'No pickup location');
    console.log('Current driverLocation:', driverLocation ? `${driverLocation.lat}, ${driverLocation.lon}` : 'No driver location');
  }, [rideId, pickupLocation, driverLocation]);

  return (
    <div className="driver-management-container">
      <nav className="tab-menu">
        <button
          className={isOnline ? 'active-online' : 'inactive-online'}
          onClick={toggleOnlineStatus}
          disabled={rideInProgress} // Disable button when a ride is in progress
        >
          {isOnline ? 'Go Offline' : 'Go Online'}
        </button>
      </nav>

      <div className="tab-content">
        {isOnline ? (
          <>
            <DriverLocationUpdate driverId={driverId} onLocationUpdate={setDriverLocation} />
            <DriverNotifications driverId={driverId} onRideAccepted={handleRideAccepted} />

            {pickupLocation && driverLocation ? (
              <DriverMap driverLocation={driverLocation} pickupLocation={pickupLocation} />
            ) : (
              <p>No valid pickup location available. Please wait for a valid ride request.</p>
            )}

            {rideId && <DriverActions rideId={rideId} onRideComplete={handleRideCompletion} />} {/* Pass the completion handler */}
          </>
        ) : (
          <p>You are currently offline. Go online to start tracking and receiving requests.</p>
        )}
      </div>
    </div>
  );
}

export default DriverManagement;
