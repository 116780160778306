import { useState, useEffect } from 'react';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);  // State to store user role

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const role = localStorage.getItem('userRole');  // Retrieve role from localStorage
    if (token && role) {
      setIsAuthenticated(true);
      setUserRole(role);  // Set the role in state
    }
  }, []);

  const apiUrl = process.env.REACT_APP_API_BASE_URL; // Use environment variable


const loginUser = async (username, password) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('userRole', data.role);

      // Store driverId if the user is a driver
      if (data.driverId) {
        localStorage.setItem('driverId', data.driverId);
      }

      setIsAuthenticated(true);
      setUserRole(data.role);
      return data;
    } else {
      throw new Error('Invalid credentials');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};


  const registerUser = async (userData) => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Registration failed');
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');  // Remove the role from localStorage
    setIsAuthenticated(false);
    setUserRole(null);  // Reset the role in state
  };

  return { isAuthenticated, userRole, loginUser, registerUser, logout };
};

export default useAuth;
