import React, { useState } from 'react';
import Profile from './Profile';
import ContrastMode from '../ContrastMode';
import LanguageSwitcher from '../LanguageSwitcher';
import './ProfileSettings.css';  // Add any custom styles here
import { FaGripLines } from 'react-icons/fa'; // FontAwesome icon for the handle

function ProfileSettings() {
  const [activeTab, setActiveTab] = useState('profile');
  const [sidebarVisible, setSidebarVisible] = useState(true); // Toggle sidebar visibility

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <Profile />;
      case 'preferences':
        return (
          <div>
            <ContrastMode />
            <LanguageSwitcher />
          </div>
        );
      default:
        return <Profile />;
    }
  };

  return (
    <div className={`profile-settings-page ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      {/* Sidebar Handle */}
      <div className={`sidebar-handle ${sidebarVisible ? 'open' : 'closed'}`} onClick={() => setSidebarVisible(!sidebarVisible)}>
        <FaGripLines />
      </div>

      <nav className={`vertical-nav ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <button className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>Profile</button>
        <button className={activeTab === 'preferences' ? 'active' : ''} onClick={() => setActiveTab('preferences')}>Preferences</button>
      </nav>

      <div className={`tab-content ${sidebarVisible ? 'with-sidebar' : 'full-width'}`}>
        {renderTabContent()}
      </div>
    </div>
  );
}

export default ProfileSettings;
