// src/components/Features/VirtualDriverAssistant.js
import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import Input from '../ui/Input';
import { Alert } from '../ui/Alert';
import { MessageSquare } from 'lucide-react';

const VirtualDriverAssistant = () => {
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);

  const sendMessage = async () => {
    if (!message.trim()) return;

    try {
      const response = await axios.post('/api/assistant/chat', { message });
      setConversation([...conversation, { user: message, assistant: response.data.reply }]);
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      Alert({ title: 'Error', description: 'Failed to send message. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><MessageSquare className="inline-block mr-2" /> Virtual Driver Assistant</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="h-60 overflow-y-auto border p-2">
            {conversation.map((entry, index) => (
              <div key={index}>
                <p className="font-bold">You: {entry.user}</p>
                <p>Assistant: {entry.assistant}</p>
              </div>
            ))}
          </div>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Ask the virtual assistant..."
          />
          <Button onClick={sendMessage}>Send</Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default VirtualDriverAssistant;
