import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import Label from '../ui/Label';
import Select from '../ui/Select';
import Slider from '../ui/Slider';
import { Alert } from '../ui/Alert';
import { Music } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';  // Assuming you have a context to manage token auth

const PersonalizedMusicExperience = () => {
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState('');
  const [musicPreference, setMusicPreference] = useState(50); // 0: Silent, 100: Loud
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('authToken');

  // Fetch available playlists from the backend
  useEffect(() => {
    const fetchPlaylists = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/music/playlists`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Use token for authentication
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch playlists');
        }

        const data = await response.json();
        setPlaylists(data);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setError('Failed to fetch playlists. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchPlaylists();
  }, [token]);

  // Handle selecting a playlist and updating the backend
  const handlePlaylistSelect = async (playlistId) => {
    setSelectedPlaylist(playlistId);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/music/set-playlist`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token for authentication
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          playlistId,
          musicVolume: musicPreference,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update music preferences');
      }

      Alert({ title: 'Success', description: 'Music preferences updated successfully!' });
    } catch (error) {
      console.error('Error setting playlist:', error);
      setError('Failed to update music preferences. Please try again.');
    }
  };

  // Show loading or error state
  if (loading) {
    return <div>Loading playlists...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle><Music className="inline-block mr-2" /> Personalized Music Experience</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Label htmlFor="playlist">Select Playlist</Label>
          <Select
            id="playlist"
            value={selectedPlaylist}
            onChange={(e) => handlePlaylistSelect(e.target.value)}
          >
            <option value="">Select a playlist</option>
            {playlists.map(playlist => (
              <option key={playlist.id} value={playlist.id}>{playlist.name}</option>
            ))}
          </Select>
          <Label htmlFor="musicPreference">Music Volume Preference</Label>
          <Slider
            id="musicPreference"
            min={0}
            max={100}
            step={1}
            value={musicPreference}
            onChange={(value) => setMusicPreference(value)}
          />
          <p>Volume: {musicPreference}%</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default PersonalizedMusicExperience;
