import React, { createContext, useState, useEffect, useContext } from 'react';

// Create the AuthContext
const AuthContext = createContext();

// Export the custom hook to access the AuthContext
export const useAuth = () => useContext(AuthContext);

// Create the provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [driverId, setDriverId] = useState(null); // Driver ID if the logged-in user is a driver

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const role = localStorage.getItem('userRole');
    const storedDriverId = localStorage.getItem('driverId'); // Get driver ID if available
    if (token && role) {
      setIsAuthenticated(true);
      setUserRole(role);
      if (role === 'DRIVER' && storedDriverId) {
        setDriverId(storedDriverId); // Set driver ID if the user is a driver
      }
    }
  }, []);

  const loginUser = async (username, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('userRole', data.role);
        if (data.role === 'DRIVER' && data.driverId) {
          localStorage.setItem('driverId', data.driverId);
          setDriverId(data.driverId);
        }
        setIsAuthenticated(true);
        setUserRole(data.role);
        return data;
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const registerUser = async (userData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Registration failed');
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('driverId'); // Clear driverId when logging out
    setIsAuthenticated(false);
    setUserRole(null);
    setDriverId(null); // Reset driverId on logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, driverId, loginUser, registerUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
