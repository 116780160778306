import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './BookingHistory.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [insights, setInsights] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All'); // Filter state
  const [rating, setRating] = useState({});
  const [review, setReview] = useState({});

  useEffect(() => {
    const fetchBookingHistory = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/UserRides`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching booking history: ${response.status}`);
        }
        const data = await response.json();
        setBookingHistory(data);
        calculateInsights(data);
      } catch (error) {
        console.error('Error fetching booking history:', error);
      }
    };

    fetchBookingHistory();
  }, []);

  const calculateInsights = (rides) => {
    const ridesWithFare = rides.filter(ride => ride.fare != null);
    const totalSpent = ridesWithFare.reduce((sum, ride) => sum + (ride.fare || 0), 0);
    const averageFare = ridesWithFare.length > 0 ? totalSpent / ridesWithFare.length : 0;
    const mostFrequentRoute = getMostFrequentRoute(rides);
    const spendingOverTime = getSpendingOverTime(ridesWithFare);

    setInsights({
      totalSpent,
      averageFare,
      mostFrequentRoute,
      spendingOverTime,
    });
  };

  const getMostFrequentRoute = (rides) => {
    const routeCounts = rides.reduce((acc, ride) => {
      const route = `${ride.pickupLocation || 'Unknown'} to ${ride.dropoffLocation || 'Unknown'}`;
      acc[route] = (acc[route] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(routeCounts).reduce((a, b) => a[1] > b[1] ? a : b)[0];
  };

  const getSpendingOverTime = (rides) => {
    const sortedRides = rides.sort((a, b) => new Date(a.bookingTime) - new Date(b.bookingTime));
    return sortedRides.map(ride => ({
      date: new Date(ride.bookingTime).toLocaleDateString(),
      amount: ride.fare || 0,
    }));
  };

  // Handle rating input
  const handleRatingChange = (rideId, ratingValue) => {
    setRating((prev) => ({
      ...prev,
      [rideId]: ratingValue,
    }));
  };

  // Handle review input
  const handleReviewChange = (rideId, reviewValue) => {
    setReview((prev) => ({
      ...prev,
      [rideId]: reviewValue,
    }));
  };

  const submitReview = async (rideId) => {
    const token = localStorage.getItem('authToken');
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rides/${rideId}/review`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rating: rating[rideId],
        review: review[rideId],
      }),
    });

    if (!response.ok) {
      console.error('Error submitting review');
    } else {
      alert('Review submitted successfully!');
    }
  };

  const filteredRides = bookingHistory.filter(
    (ride) => statusFilter === 'All' || ride.status === statusFilter
  );

  return (
    <div className="booking-history-container">
      <div className="booking-cards-container">
        <h2>Your Booking History</h2>

        {/* Filter Section */}
        <div className="filter-section">
          <label>Status: </label>
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="All">All</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Ongoing">Ongoing</option>
          </select>
        </div>

        {filteredRides.length > 0 ? (
          filteredRides.map((ride) => (
            <div key={ride.rideId} className="booking-card">
              <h3>Ride #{ride.rideId}</h3>
              <p><strong>Pickup:</strong> {ride.pickupLocation || 'Unknown'}</p>
              <p><strong>Dropoff:</strong> {ride.dropoffLocation || 'Unknown'}</p>
              <p><strong>Booking Time:</strong> {new Date(ride.bookingTime).toLocaleString()}</p>
              <p><strong>Status:</strong> {ride.status || 'Unknown'}</p>
              <p><strong>Fare:</strong> {ride.fare != null ? `$${ride.fare.toFixed(2)}` : 'Not available'}</p>

              {ride.pickupCoordinates && ride.dropoffCoordinates && (
                <MapContainer center={ride.pickupCoordinates} zoom={13} style={{ height: '200px', width: '100%' }}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Marker position={ride.pickupCoordinates}>
                    <Popup>Pickup Location</Popup>
                  </Marker>
                  <Marker position={ride.dropoffCoordinates}>
                    <Popup>Dropoff Location</Popup>
                  </Marker>
                </MapContainer>
              )}

              {/* Review and Rating Section */}
              <div className="review-section">
                <h4>Rate this Ride:</h4>
                <div className="rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleRatingChange(ride.rideId, star)}
                      style={{ color: rating[ride.rideId] >= star ? 'gold' : 'gray', cursor: 'pointer' }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <textarea
                  placeholder="Write your review here..."
                  value={review[ride.rideId] || ''}
                  onChange={(e) => handleReviewChange(ride.rideId, e.target.value)}
                ></textarea>
                <button onClick={() => submitReview(ride.rideId)}>Submit Review</button>
              </div>
            </div>
          ))
        ) : (
          <p>No rides found in your history.</p>
        )}
      </div>

      {insights && (
        <div className="insights-container">
          <h3>Your Ride Insights</h3>
          <p><strong>Total Spent:</strong> ${insights.totalSpent.toFixed(2)}</p>
          <p><strong>Average Fare:</strong> ${insights.averageFare.toFixed(2)}</p>
          <p><strong>Most Frequent Route:</strong> {insights.mostFrequentRoute}</p>
          <div className="spending-chart">
            <Line
              data={{
                labels: insights.spendingOverTime.map(item => item.date),
                datasets: [{
                  label: 'Spending Over Time',
                  data: insights.spendingOverTime.map(item => item.amount),
                  borderColor: 'rgb(75, 192, 192)',
                  tension: 0.1
                }]
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Spending Over Time'
                  }
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingHistory;