// src/components/SOSButton.js
import React from 'react';
import { toast } from 'react-toastify';

const SOSButton = ({ userId, rideId }) => {
  const handleSOSAlert = async () => {
    try {
      const response = await fetch('/api/v1/sos/alert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ userId, rideId }),
      });
      if (response.ok) {
        toast.success('Emergency alert sent successfully!');
      } else {
        throw new Error('Failed to send alert.');
      }
    } catch (error) {
      toast.error('Error sending SOS alert');
      console.error('SOS Alert Error:', error);
    }
  };

  return (
    <button onClick={handleSOSAlert} className="sos-button">
      SOS
    </button>
  );
};
export default SOSButton;
