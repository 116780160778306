import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function DriverLocationUpdate({ driverId, onLocationUpdate }) {
  const [tracking, setTracking] = useState(false);

  useEffect(() => {
    if (!driverId) {
      toast.error('Driver ID is missing. Cannot track location.');
      return;
    }

    const updateLocation = async (latitude, longitude) => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/drivers/update-location/${driverId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ latitude, longitude }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to update location: ${errorText}`);
        }

        console.log('Location updated');
        onLocationUpdate({ lat: latitude, lon: longitude });
      } catch (error) {
        console.error('Error updating location:', error.message);
        toast.error(`Error updating location: ${error.message}`);
      }
    };

    const startTracking = () => {
      if (navigator.geolocation) {
        const trackingId = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            updateLocation(latitude, longitude);
          },
          (error) => {
            console.error('Error getting location:', error.message);
            toast.error(`Error getting location: ${error.message}`);
          },
          { enableHighAccuracy: true, maximumAge: 5000, timeout: 5000 }
        );

        return trackingId;
      } else {
        toast.error('Geolocation is not supported by this browser.');
        return null;
      }
    };

    const trackingId = startTracking();

    return () => {
      if (navigator.geolocation && trackingId) {
        navigator.geolocation.clearWatch(trackingId);
      }
    };
  }, [driverId, onLocationUpdate]);

  return (
    <div className="driver-tracking-status">
      <p className="tracking-on">Tracking driver's location and sending updates to the server.</p>
    </div>
  );
}

export default DriverLocationUpdate;
