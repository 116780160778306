import React from 'react';
import PersonalizedMusicExperience from './PersonalizedMusicExperience';
import LocalTourGuideOption from './LocalTourGuideOption';
import DynamicPricing from './DynamicPricing';
import VirtualDriverAssistant from './VirtualDriverAssistant';
import MoodBasedRideExperience from './MoodBasedRideExperience';
import IntegratedMultiModalTransportation from './IntegratedMultiModalTransportation';
import './AdvancedFeatures.css';  // Import the CSS file

const AdvancedFeatures = () => {
  return (
    <div className="features-hub-container">
      <div className="features-grid">
        <div className="card">
          <div className="card-header">Personalized Music Experience</div>
          <div className="card-content">
            <PersonalizedMusicExperience />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Local Tour Guide Option</div>
          <div className="card-content">
            <LocalTourGuideOption />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Dynamic Pricing</div>
          <div className="card-content">
            <DynamicPricing />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Virtual Driver Assistant</div>
          <div className="card-content">
            <VirtualDriverAssistant />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Mood-Based Ride Experience</div>
          <div className="card-content">
            <MoodBasedRideExperience />
          </div>
        </div>
        <div className="card">
          <div className="card-header">Integrated Multi-Modal Transportation</div>
          <div className="card-content">
            <IntegratedMultiModalTransportation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedFeatures;
