import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

const DriverMap = ({ driverLocation, pickupLocation }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && driverLocation && pickupLocation) {
      L.Routing.control({
        waypoints: [
          L.latLng(driverLocation.lat, driverLocation.lon),
          L.latLng(pickupLocation.lat, pickupLocation.lon),
        ],
        routeWhileDragging: true,
      }).addTo(mapRef.current);
    }
  }, [driverLocation, pickupLocation]);

  return (
    <div className="map-container">
      <MapContainer
        center={[driverLocation.lat, driverLocation.lon]}
        zoom={13}
        ref={mapRef}
        style={{ height: '400px', width: '100%', borderRadius: '10px' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />
        <Marker position={[driverLocation.lat, driverLocation.lon]} />
        <Marker position={[pickupLocation.lat, pickupLocation.lon]} />
        <Polyline
          positions={[
            [driverLocation.lat, driverLocation.lon],
            [pickupLocation.lat, pickupLocation.lon],
          ]}
          color="blue"
        />
      </MapContainer>
    </div>
  );
};

export default DriverMap;
