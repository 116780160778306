import React from 'react';

const Checkbox = ({ checked, onCheckedChange, children }) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onCheckedChange(e.target.checked)}
      />
      {children}
    </label>
  );
};

export default Checkbox;
