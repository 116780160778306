import React, { useState } from 'react';
import AdvancedFeatures from './AdvancedFeatures';
import InnovativeFeatures from './InnovativeFeatures';
import UniqueFeatures from './UniqueFeatures';
import './FeaturesHub.css';  // Add any custom styles here
import { FaGripLines } from 'react-icons/fa'; // FontAwesome icon for the handle

function FeaturesHub() {
  const [activeTab, setActiveTab] = useState('advanced-features');
  const [sidebarVisible, setSidebarVisible] = useState(true); // Toggle sidebar visibility

  const renderTabContent = () => {
    switch (activeTab) {
      case 'advanced-features':
        return <AdvancedFeatures />;
      case 'innovative-features':
        return <InnovativeFeatures />;
      case 'unique-features':
        return <UniqueFeatures />;
      default:
        return <AdvancedFeatures />;
    }
  };

  return (
    <div className={`features-hub-container ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      {/* Sidebar Handle */}
      <div className={`sidebar-handle ${sidebarVisible ? 'open' : 'closed'}`} onClick={() => setSidebarVisible(!sidebarVisible)}>
        <FaGripLines />
      </div>

      {/* Sidebar navigation */}
      <nav className={`vertical-nav ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <button
          className={activeTab === 'advanced-features' ? 'active' : ''}
          onClick={() => setActiveTab('advanced-features')}
        >
          Cutting-Edge Technology
        </button>
        <button
          className={activeTab === 'innovative-features' ? 'active' : ''}
          onClick={() => setActiveTab('innovative-features')}
        >
          Innovative Services
        </button>
        <button
          className={activeTab === 'unique-features' ? 'active' : ''}
          onClick={() => setActiveTab('unique-features')}
        >
          Exclusive Offerings
        </button>
      </nav>

      {/* Main content area */}
      <div className={`tab-content ${sidebarVisible ? 'with-sidebar' : 'full-width'}`}>
        {renderTabContent()}
      </div>
    </div>
  );
}

export default FeaturesHub;
